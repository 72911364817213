import React, { useState } from "react";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(
        "https://dt-priyanka-1.onrender.com/api/admin/login",
        // "http://localhost:5000/api/admin/login",
        {
          username,
          password,
        }
      );
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        // alert("Login successful!");
        window.location.href = "/admin-plan";
      } else {
        setError("Invalid credentials");
      }
    } catch (err) {
      setError("Error logging in");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center">Admin Login</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-100"
              required
            />
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-100"
              required
            />
          </div>
          {error && <div className="text-red-500">{error}</div>}
          <button
            type="submit"
            className="w-full py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

// import React, { useState } from "react";
// import axios from "axios";

// const Login = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [isRegistering, setIsRegistering] = useState(false); // State to toggle between login and register

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError("");

//     const url = isRegistering
//       ? "https://dt-priyanka-1.onrender.com/api/admin/register"
//       : "https://dt-priyanka-1.onrender.com/api/admin/login";

//     try {
//       const response = await axios.post(url, { username, password });
//       if (response.data.success) {
//         alert(isRegistering ? "Registration successful!" : "Login successful!");
//       } else {
//         setError(response.data.message || "An error occurred");
//       }
//     } catch (err) {
//       setError("An error occurred");
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
//         <h2 className="text-2xl font-bold text-center">
//           {isRegistering ? "Register" : "Admin Login"}
//         </h2>
//         <form onSubmit={handleSubmit} className="space-y-6">
//           <div>
//             <label className="block mb-1 text-sm font-medium text-gray-700">
//               Username
//             </label>
//             <input
//               type="text"
//               value={username}
//               onChange={(e) => setUsername(e.target.value)}
//               className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-100"
//               required
//             />
//           </div>
//           <div>
//             <label className="block mb-1 text-sm font-medium text-gray-700">
//               Password
//             </label>
//             <input
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-100"
//               required
//             />
//           </div>
//           {error && <div className="text-red-500">{error}</div>}
//           <button
//             type="submit"
//             className="w-full py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
//           >
//             {isRegistering ? "Register" : "Login"}
//           </button>
//         </form>
//         <div className="text-center">
//           <button
//             onClick={() => setIsRegistering(!isRegistering)}
//             className="mt-4 text-sm text-blue-500 hover:underline"
//           >
//             {isRegistering
//               ? "Already have an account? Login"
//               : "Don't have an account? Register"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;
