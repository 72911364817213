import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminPanel = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [editingBlog, setEditingBlog] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://dt-priyanka-1.onrender.com/api/blogs"
      );
      setBlogs(response.data);
    } catch (err) {
      setError("Error fetching blogs");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("author", author);
    formData.append("category", category);
    if (image) {
      formData.append("image", image);
    }

    try {
      const config = {
        headers: {
          "x-auth-token": token,
          "Content-Type": "multipart/form-data",
        },
      };

      if (editingBlog) {
        await axios.put(
          `https://dt-priyanka-1.onrender.com/api/blogs/${editingBlog._id}`,
          formData,
          config
        );
        toast.success("Blog updated successfully!");
        setEditingBlog(null);
      } else {
        await axios.post(
          "https://dt-priyanka-1.onrender.com/api/blogs",
          formData,
          config
        );
        toast.success("Blog created successfully!");
      }

      setTitle("");
      setContent("");
      setAuthor("");
      setCategory("");
      setImage(null);
      fetchBlogs();
    } catch (err) {
      setError("Error saving blog");
      toast.error("Error saving blog");
    }
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
    setTitle(blog.title);
    setContent(blog.content);
    setAuthor(blog.author);
    setCategory(blog.category);
    setImage(blog.image);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`https://dt-priyanka-1.onrender.com/api/blogs/${id}`, {
        headers: { "x-auth-token": token },
      });
      toast.success("Blog deleted successfully!");
      fetchBlogs();
    } catch (err) {
      setError("Error deleting blog");
      toast.error("Error deleting blog");
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
  };

  return (
    <div className="mt-28 container mx-auto p-4">
      <ToastContainer />
      <h1 className="flex justify-center align-items-center text-4xl font-bold mb-4">
        Admin Panel
      </h1>
      <div className="h-1 w-full bg-[#0F4B7C] my-6"></div>
      <h2 className="flex justify-center align-items-center font-bold text-[4vh]">
        Create New Blogs
      </h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSave} className="mb-4 space-y-4">
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Content
          </label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          ></textarea>
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Author
          </label>
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Category
          </label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Image
          </label>
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required={!editingBlog}
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg"
        >
          {editingBlog ? "Update Blog" : "Create Blog"}
        </button>
      </form>
      <div className="h-1 w-full bg-[#0F4B7C] my-6"></div>
      <div className="flex justify-center align-items-center font-bold text-[4vh]">
        Previous Blogs
      </div>
      <div className="space-y-4">
        {blogs.map((blog) => (
          <div key={blog._id} className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold">{blog.title}</h2>
            <p>{blog.content}</p>
            <p className="text-sm text-gray-600">By {blog.author}</p>
            <p className="text-sm text-gray-600">Category: {blog.category}</p>
            {blog.image && (
              <img
                src={require(`../../uploadImages/${blog.image}`)}
                alt={blog.title}
                className="mt-2 h-10 w-10"
              />
            )}
            <div className="mt-4 space-x-2">
              <button
                onClick={() => handleEdit(blog)}
                className="px-4 py-2 bg-yellow-500 text-white rounded-lg"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(blog._id)}
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPanel;
