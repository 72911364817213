import React from "react";
import "animate.css";

const CancellationRefundPolicy = () => {
  return (
    <div className="container mx-auto p-4 mt-28">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8 animate__animated animate__fadeInDown">
        Cancellation & Refund Policy
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-lg animate__animated animate__fadeInUp">
        <p className="mb-4 text-gray-700">
          We at Dietician Priyanka strive to provide the best service possible
          to our clients. However, we understand that circumstances may arise
          where you may need to cancel or request a refund for our services.
          This policy outlines the conditions under which cancellations and
          refunds will be processed.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Cancellation Policy
        </h2>
        <p className="mb-4 text-gray-700">
          If you wish to cancel a service or appointment, we require a minimum
          notice period of 24 hours. Cancellations made within this time frame
          will not incur any penalties. However, cancellations made less than 24
          hours before the scheduled appointment may be subject to a
          cancellation fee of up to 50% of the service cost.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Refund Policy
        </h2>
        <p className="mb-4 text-gray-700">
          Refunds for services will only be processed under specific
          circumstances. If you are dissatisfied with a service or feel that it
          was not delivered as promised, please contact us immediately. Refund
          requests must be submitted within 7 days of the service date. We will
          review your request and, if approved, a refund will be processed to
          your original payment method within 14 business days.
        </p>
        <p className="mb-4 text-gray-700">
          Please note that any digital products, such as downloadable meal plans
          or e-books, are non-refundable once they have been delivered to you.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Non-Refundable Services
        </h2>
        <p className="mb-4 text-gray-700">
          Certain services, such as personalized nutrition plans or one-on-one
          consultations, may be non-refundable due to the customized nature of
          the service. These services will be clearly marked as non-refundable
          at the time of purchase.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Contact Us
        </h2>
        <p className="text-gray-700">
          If you have any questions about our Cancellation & Refund Policy or
          need to request a cancellation or refund, please reach out to us at{" "}
          <a
            href="mailto:priyankadlm@gmail.com"
            className="text-blue-500 underline"
          >
            priyankadlm@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default CancellationRefundPolicy;
