import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const AnemiaDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is anemia and how does diet affect it?",
      answer:
        "Anemia is a medical condition characterized by a deficiency in the number or quality of red blood cells (RBCs) in the body. RBCs are responsible for carrying oxygen from the lungs to the rest of the body. When the body doesn't have enough healthy RBCs, or when the hemoglobin (a protein in RBCs) levels are low, it can result in fatigue, weakness, and other symptoms. The primary role of diet in managing anemia is to ensure the body gets adequate amounts of essential nutrients that are critical for RBC production, such as iron, vitamin B12, and folate. A well-balanced diet rich in these nutrients can help prevent anemia and manage existing conditions by improving the production and quality of RBCs.",
    },
    {
      question: "What are the best dietary sources of iron?",
      answer:
        "Iron is a crucial nutrient that plays a vital role in the production of hemoglobin, which is necessary for oxygen transport in the blood. The best dietary sources of iron include both heme iron (found in animal products) and non-heme iron (found in plant-based foods). Heme iron is more easily absorbed by the body and can be found in foods like lean meats (such as beef, pork, and lamb), poultry (especially dark meat), and fish (such as salmon, tuna, and sardines). Non-heme iron sources include legumes (beans, lentils, chickpeas), tofu, spinach, quinoa, fortified cereals, nuts, and seeds. To enhance the absorption of non-heme iron, it's beneficial to consume these foods alongside vitamin C-rich foods like oranges, strawberries, bell peppers, or tomatoes.",
    },
    {
      question: "How can I incorporate vitamin B12 into my diet?",
      answer:
        "Vitamin B12 is essential for the proper formation of red blood cells and neurological function. It is naturally found in animal products, so those following a diet that includes meat, fish, dairy, and eggs are likely to get sufficient amounts of this vitamin. Foods high in vitamin B12 include beef liver, clams, fish (such as salmon and trout), meat (such as beef, pork, and poultry), eggs, and dairy products like milk, cheese, and yogurt. For vegetarians or vegans, fortified foods such as plant-based milks (like almond or soy milk), breakfast cereals, nutritional yeast, and meat substitutes are recommended sources. Additionally, those who are at risk of a B12 deficiency (such as the elderly or those with certain medical conditions) may benefit from B12 supplements, which are available in various forms including pills, sublingual tablets, or injections.",
    },
    {
      question: "What role does folate play in anemia?",
      answer:
        "Folate, also known as vitamin B9, is a key nutrient that helps produce and maintain new cells, including red blood cells. It is particularly important during periods of rapid growth, such as pregnancy and infancy, but it is essential for everyone to prevent anemia. Folate deficiency can lead to a type of anemia known as megaloblastic anemia, where the red blood cells are larger than normal and not fully developed, leading to symptoms like fatigue and weakness. Folate is abundant in many foods, especially leafy green vegetables (such as spinach, kale, and broccoli), legumes (beans, lentils, peas), fruits (such as oranges, bananas, and melons), nuts, seeds, and fortified grains. Including a variety of these foods in your diet can help ensure adequate folate intake, supporting overall health and preventing anemia.",
    },
    {
      question: "What should I avoid in an anemia diet?",
      answer:
        "In an anemia diet, certain foods and beverages should be limited or avoided as they can interfere with the body's ability to absorb iron, an essential nutrient for managing anemia. Foods high in calcium, such as dairy products (milk, cheese, yogurt), can inhibit iron absorption when consumed in large quantities with iron-rich meals. Similarly, tannins found in tea, coffee, and some red wines can also reduce the absorption of non-heme iron from plant-based foods. Additionally, high levels of phytates in foods like whole grains, legumes, and nuts can bind to iron and prevent its absorption. While these foods are nutritious and beneficial in many ways, they should be consumed in moderation or at different times than iron-rich foods. Excessive alcohol consumption should also be avoided as it can lead to a decrease in red blood cell production and can exacerbate anemia symptoms.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Anemia Diet - Nutritional Guidance for Managing Anemia</title>
        <meta
          name="description"
          content="Learn about the best dietary practices for managing anemia, including essential nutrients, foods to include, and those to avoid."
        />
        <meta
          name="keywords"
          content="anemia diet, iron-rich foods, vitamin B12, folate, nutrition, anemia management"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Anemia Diet</h1>
            <p className="mt-2 text-md">
              Discover nutritional strategies to manage and prevent anemia with
              our comprehensive diet guide.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          {/* Iron-Rich Foods Section */}
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Iron-Rich Foods
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/7.avif"
                alt="Iron-Rich Foods"
                className="w-full h-96 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                Consuming iron-rich foods is essential for managing anemia. Iron
                is a key component of hemoglobin, which helps carry oxygen
                throughout the body. Foods like lean meats, fish, legumes, and
                spinach are excellent sources of iron. Including a variety of
                these foods in your diet can help ensure that your body has
                enough iron to produce healthy red blood cells.
              </p>
              <p className="mb-4">
                Pairing these foods with vitamin C-rich items can enhance iron
                absorption. For example, combining spinach with citrus fruits or
                adding bell peppers to your meals can increase the amount of
                iron your body absorbs. It's also important to balance your diet
                with other nutrients to support overall health and effective
                anemia management.
              </p>
            </div>
          </section>

          {/* Vitamin B12 Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Vitamin B12
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              {/* <div className="flex gap-4 items-center justify-center mb-4 flex-wrap md:flex-nowrap">
                <div className="w-full md:w-1/2 p-2">
                  <img
                    src="/img/WeightGain.webp"
                    alt="Vitamin B12 Sources"
                    className="w-full h-72 object-cover rounded-lg"
                  />
                </div>
                <div className="w-full md:w-1/2 p-2">
                  <img
                    src="/img/abs1.jpg"
                    alt="Vitamin B12 Sources"
                    className="w-full h-72 object-cover rounded-lg"
                  />
                </div>
              </div> */}
              <img
                src="/img/8.png"
                alt="Folate and Anemia"
                className="w-full h-full object-cover rounded-lg"
              />
              <p className="mb-4">
                Vitamin B12 is crucial for red blood cell production and can be
                found in animal products like meat, dairy, and eggs. It is also
                vital for neurological function and DNA synthesis. For those on
                plant-based diets, fortified foods and supplements may be
                necessary. Plant-based eaters can opt for fortified cereals,
                plant milks, and nutritional yeast, which can provide the
                necessary B12 to avoid deficiencies.
              </p>
              <p className="mb-4">
                It's important to regularly include these sources in your diet
                to prevent deficiencies and support overall health. If you're
                unable to get enough vitamin B12 from food alone, especially if
                you follow a vegetarian or vegan diet, consider taking a
                supplement to ensure you're meeting your nutritional needs.
              </p>
            </div>
          </section>

          {/* Folate Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Folate and Anemia
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <p className="mb-4">
                Folate is essential for red blood cell production. It is
                particularly important for women of childbearing age as it helps
                prevent neural tube defects in developing fetuses. Foods rich in
                folate include leafy greens, legumes, and fortified grains.
                Incorporating these foods into your daily diet can help ensure
                that your body has enough folate to produce healthy red blood
                cells and prevent anemia.
              </p>
              <p className="mb-4">
                Adequate folate intake is vital for preventing anemia and
                maintaining good health. In addition to leafy greens and
                legumes, you can also find folate in foods like avocados,
                broccoli, and oranges. By eating a diverse diet rich in these
                foods, you can meet your folate needs and support your body's
                overall health.
              </p>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AnemiaDiet;
