import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const PCODandObesityDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a PCOD and Obesity Diet?",
      answer:
        "A PCOD and Obesity Diet is designed to help manage symptoms of PCOD and support healthy weight management through balanced nutrition and lifestyle changes.",
    },
    {
      question: "How does this diet help manage PCOD and obesity?",
      answer:
        "This diet helps manage PCOD and obesity by providing specific dietary recommendations that support hormonal balance, reduce inflammation, and promote healthy weight loss.",
    },
    {
      question: "What foods should I include in my diet?",
      answer:
        "Include foods rich in fiber, lean proteins, healthy fats, and low-glycemic index carbohydrates. Avoid processed foods, sugary snacks, and refined carbohydrates.",
    },
    {
      question: "Can I still enjoy my favorite foods?",
      answer:
        "Yes, with moderation and careful planning. Our dietitians will help you incorporate your favorite foods into a balanced diet, ensuring you enjoy your meals while managing your health.",
    },
    {
      question: "How do I get started?",
      answer:
        "Book an appointment with our experts to get a personalized diet plan. Our team will support you through every step, ensuring you achieve your health goals.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>PCOD and Obesity Diet - Manage Your Health</title>
        <meta
          name="description"
          content="Join our PCOD and Obesity Diet program to manage symptoms and achieve healthy weight loss through balanced nutrition and lifestyle changes."
        />
        <meta
          name="keywords"
          content="PCOD, obesity, diet, health, nutrition, weight loss"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">PCOD and Obesity Diet</h1>
            <p className="mt-2 text-md">
              Manage your health with balanced nutrition and lifestyle changes.
            </p>
          </div>
        </header>

        <section className="container mx-auto py-12 px-6 lg:px-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Success Stories
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 justify-center">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">500+</p>
                <p className="mt-2 text-gray-600">Satisfied Clients</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">15kg</p>
                <p className="mt-2 text-gray-600">Max Weight Loss</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">8kg</p>
                <p className="mt-2 text-gray-600">Avg. Weight Loss</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <img
              src="/img/services.jpg"
              alt="Healthy Food"
              className="w-full h-full object-cover rounded-lg"
            />
            <h3 className="text-2xl font-bold mb-4">Ideal for</h3>
            <p className="mb-4">
              Our PCOD and Obesity Diet program is ideal for individuals looking
              to manage PCOD symptoms and achieve healthy weight loss through
              balanced nutrition and lifestyle changes.
            </p>
            <h4 className="text-xl font-semibold mb-4">Balanced Nutrition</h4>
            <p className="mb-4">
              Our diet plans provide the essential nutrients needed to support
              hormonal balance, reduce inflammation, and promote healthy weight
              loss.
            </p>
            <p className="mb-4">
              We emphasize sustainable lifestyle changes to promote long-term
              health benefits.
            </p>
            <p className="mb-4">
              Individual results vary based on factors like diet, medical
              history, activity level, and mental health. Our goal is
              comprehensive health improvement.
            </p>
            <p className="mb-4">
              Our diet plans promote permanent lifestyle changes and realistic
              goal-setting, fostering long-term health benefits.
            </p>
            <img
              src="/img/chicken.jpg"
              alt="High-Calorie Foods"
              className="w-full h-full object-cover rounded-lg mb-6"
            />
            <h4 className="text-xl font-semibold mb-4">Regular Exercise</h4>
            <p className="mb-4">
              Engaging in regular physical activity is crucial for managing PCOD
              and obesity. Our program includes personalized exercise routines
              suitable for all fitness levels.
            </p>
            <p className="mb-4">
              Exercise not only aids in weight loss but also improves overall
              cardiovascular health, strength, and endurance.
            </p>
            <img
              src="/img/strength.jpg"
              alt="Strength Training"
              className="w-full h-full object-cover rounded-lg mb-6"
            />
            <h4 className="text-xl font-semibold mb-4">Frequent Meals</h4>
            <p className="mb-4">
              Eating small, frequent meals throughout the day can help increase
              your overall calorie intake. Our Dietician Priyanka will guide you
              in planning meals that are balanced, nutritious, and conducive to
              weight loss.
            </p>
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Personalized diet and exercise plans tailored to your needs and
                goals.
              </li>
              <li className="mb-2">
                Expert guidance from certified Dietician Priyanka and fitness
                trainers.
              </li>
              <li className="mb-2">
                Focus on sustainable weight loss strategies that ensure
                long-term results.
              </li>
              <li className="mb-2">
                Regular progress tracking and adjustments to optimize your
                health journey.
              </li>
              <li className="mb-2">
                Access to a supportive community and resources for motivation
                and encouragement.
              </li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Program Features"
              className="w-full h-full object-cover rounded-lg mb-6"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold">Frequently Asked Questions</h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PCODandObesityDiet;
