import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const ThyroidDiseaseDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a Thyroid Disease Diet?",
      answer:
        "A Thyroid Disease Diet is designed to support thyroid health and manage symptoms of thyroid disorders through balanced nutrition and lifestyle changes.",
    },
    {
      question: "How does this diet help manage thyroid disease?",
      answer:
        "This diet helps manage thyroid disease by providing specific dietary recommendations that support thyroid function, reduce inflammation, and improve overall health.",
    },
    {
      question: "What foods should I include in my diet?",
      answer:
        "Include foods rich in iodine, selenium, and zinc, such as seafood, nuts, and seeds. Avoid processed foods and those high in goitrogens that can interfere with thyroid function.",
    },
    {
      question: "Can I still enjoy my favorite foods?",
      answer:
        "Yes, with moderation and careful planning. Our dietitians will help you incorporate your favorite foods into a balanced diet, ensuring you enjoy your meals while managing your thyroid health.",
    },
    {
      question: "How do I get started?",
      answer:
        "Book an appointment with our experts to get a personalized diet plan. Our team will support you through every step, ensuring you achieve your health goals.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Thyroid Disease Diet - Support Your Thyroid Health</title>
        <meta
          name="description"
          content="Join our Thyroid Disease Diet program to support thyroid health and manage thyroid disorders through balanced nutrition and lifestyle changes."
        />
        <meta
          name="keywords"
          content="thyroid disease, thyroid diet, health, nutrition, thyroid health"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Thyroid Disease Diet</h1>
            <p className="mt-2 text-md">
              Support your thyroid health with balanced nutrition and lifestyle
              changes.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          <section className="text-center mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">2000+</p>
                <p className="mt-2 text-gray-600">Patients Supported</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">80%</p>
                <p className="mt-2 text-gray-600">Symptom Improvement</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">90%</p>
                <p className="mt-2 text-gray-600">Increased Energy Levels</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-3s">
                <p className="text-3xl font-bold">75%</p>
                <p className="mt-2 text-gray-600">Improved Well-being</p>
              </div>
            </div>
          </section>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <img
              src="/img/services.jpg"
              alt="Healthy Food"
              className="w-full h-auto object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-bold mb-4">Ideal for</h3>
            <p className="mb-4">
              Our Thyroid Disease Diet program is ideal for individuals looking
              to manage thyroid disorders through balanced nutrition and
              lifestyle changes.
            </p>
            <h4 className="text-xl font-semibold mb-4">Nutritional Support</h4>
            <p className="mb-4">
              Our diet plans provide the essential nutrients needed to support
              thyroid function and overall health.
            </p>
            <p className="mb-4">
              We emphasize sustainable lifestyle changes to promote long-term
              thyroid health.
            </p>
            <p className="mb-4">
              Individual results vary based on factors like diet, medical
              history, activity level, and mental health. Our goal is
              comprehensive health improvement.
            </p>
            <p className="mb-4">
              Our diet plans promote permanent lifestyle changes and realistic
              goal-setting, fostering long-term health benefits.
            </p>
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </article>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <img
              src="/img/Exercise.png"
              alt="Exercise Routine"
              className="w-full h-full object-cover rounded-lg"
            />
            <h3 className="text-2xl font-bold mb-4">Exercise and Activity</h3>
            <p className="mb-4">
              Regular physical activity is essential for managing thyroid
              disorders. Our program includes personalized exercise routines
              suitable for all fitness levels.
            </p>
            <p className="mb-4">
              Whether you're a beginner or an experienced athlete, our trainers
              will guide you through effective workouts tailored to your needs.
            </p>
            <p className="mb-4">
              Exercise not only aids in managing thyroid conditions but also
              improves overall cardiovascular health, strength, and endurance.
            </p>
            <h3 className="text-2xl font-bold mb-4">Dietary Counseling</h3>
            <p className="mb-4">
              Our expert dietitians provide personalized counseling to help you
              make informed food choices and achieve your health goals.
            </p>
            <p className="mb-4">
              We focus on a balanced diet that includes all essential nutrients
              while promoting long-term health benefits.
            </p>
            <p className="mb-4">
              Dietary counseling is integral to our program, ensuring that you
              develop healthy eating habits that last a lifetime.
            </p>
          </article>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-3s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Comprehensive support from a dedicated team of experienced
                dietitians and Dietician Priyanka.
              </li>
              <li className="mb-2">
                Personalized diet plans focusing on holistic health improvement.
              </li>
              <li className="mb-2">
                Education on food choices and their long-term health benefits.
              </li>
              <li className="mb-2">
                Flexible programs that don’t feel restrictive.
              </li>
              <li className="mb-2">
                Emphasis on sustainable lifestyle changes rather than calorie
                counting alone.
              </li>
              <li className="mb-2">
                Convenient app-based tracking and support through 'Health
                Tracker'.
              </li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Support"
              className="w-full h-full object-cover rounded-lg"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </article>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ThyroidDiseaseDiet;
