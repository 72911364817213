import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner"; // Import the spinner component
import { Helmet } from "react-helmet";

const BookAppointment = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    disease: "",
    date: "",
  });

  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation: check if all fields are filled
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.disease ||
      !formData.date
    ) {
      toast.error("Please fill out all fields.");
      return;
    }

    setIsLoading(true); // Start loading spinner

    try {
      const response = await axios.post(
        "https://dt-priyanka-1.onrender.com/api/appointments",
        formData
      );

      if (response.data.success) {
        toast.success("Appointment booked successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          disease: "",
          date: "",
        });
      } else {
        toast.error(`Error: ${response.data.error.message || "Unknown error"}`);
      }
    } catch (err) {
      toast.error(
        `Error booking appointment: ${
          err.response?.data?.error?.message || err.message
        }`
      );
    } finally {
      setIsLoading(false); // End loading spinner
    }
  };

  return (
    <>
      <Helmet>
        <title>Appointment | Dietician Priyanka</title>
      </Helmet>
      <ToastContainer />
      <div className="mt-20 container mx-auto px-5">
        <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
          <div className="absolute inset-0 top-0 flex items-start justify-center">
            <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
          </div>
          <div className="z-[1]">
            <img src="T-logo.png" alt="" />
          </div>
          <div className="flex flex-col gap-4 items-center z-[1]">
            <div>
              <h1 className="text-2xl font-semibold">Book an Appointment</h1>
            </div>
            <div className="text-left">
              <p className="text-left">
                We are excited to help you on your journey to better health!
                Booking an appointment with us is simple and convenient. Here’s
                how it works:
              </p>
              <p>
                <span className="font-semibold">Check Availability:</span>{" "}
                Select your preferred date and time. If the slot is available,
                it will be reserved for you immediately.
              </p>
              <p>
                <span className="font-semibold">Alternative Options:</span> If
                your preferred slot is not available, we will provide you with a
                list of alternative free slots. You can choose any of these
                available times that best fit your schedule.
              </p>
              <p>
                <span className="font-semibold">Confirmation:</span> Once you
                have selected a slot, you will receive a confirmation via email
                or WhatsApp. This confirmation will include all the details of
                your appointment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-6 max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg relative">
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">
          Book an Appointment
        </h2>
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-10">
            <ThreeDots
              height="50"
              width="50"
              color="#0A7CEA"
              ariaLabel="loading"
            />
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-medium mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Disease / Appointment Reason
            </label>
            <input
              type="text"
              name="disease"
              value={formData.disease}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-1">Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded font-bold hover:bg-blue-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading} // Disable button when loading
          >
            Book Appointment
          </button>
        </form>
      </div>
    </>
  );
};

export default BookAppointment;
