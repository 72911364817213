import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Pricing from "../components/pricing/Pricing";
import Table from "../components/pricing/Table";
import Faq from "../components/pricing/Faq";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Prices = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Plans | Dietician Priyanka</title>
      </Helmet>
      <div className="mt-20 container mx-auto px-5 ">
        <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
          <div className="absolute inset-0 top-0 flex items-start justify-center">
            <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
          </div>
          <div className=" z-[1]">
            <img src="T-logo.png" alt="" />
          </div>
          <div className="flex flex-col gap-4 items-center  z-[1]">
            <div>
              <h1 className="text-2xl font-semibold">Our Pricing</h1>{" "}
            </div>
            <div>
              {" "}
              <p className="text-center">
                At Dietician Priyanka, we offer flexible pricing options to
                accommodate your unique requirements and budget. Our goal is to
                provide you with exceptional personalized dietician coaching
                that is accessible and tailored to your needs. Choose from our
                three plans below and take the first step towards a healthier
                lifestyle
              </p>{" "}
            </div>
          </div>
        </div>

        <Pricing />
        {/* Consultation Section */}
        <div className="flex justify-center mt-10">
          <div className="p-4  w-full">
            <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
              <h2 className="text-2xl tracking-widest title-font mb-1 font-bold flex align-middle justify-center">
                Consultation
              </h2>
              <h2 className="text-md tracking-widest title-font font-normal flex align-middle justify-center mb-5">
                One-time Consultation
              </h2>
              <p className="flex items-center justify-center text-gray-600 mb-5">
                Schedule a one-time consultation with our expert dietician to
                address your specific health and nutrition needs. This session
                is tailored to help you understand your dietary requirements,
                set achievable health goals, and create a personalized plan that
                suits your lifestyle. Whether you're looking to improve your
                eating habits, manage a health condition, or simply seek expert
                advice, our consultation provides a comprehensive approach to
                your well-being.
              </p>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 mb-4">
                <span>₹500</span>
              </h1>
              <Link to="/appointment">
                <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
                  Book Consultation
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="p-4  w-full">
            <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
              <h2 className="text-2xl tracking-widest title-font mb-1 font-bold flex align-middle justify-center">
                Therapeutic Diet Plan
              </h2>
              <h2 className="text-md tracking-widest title-font font-normal flex align-middle justify-center mb-5">
                Therapeutic Diet Plan with Do's and Don'ts
              </h2>
              <p className="flex items-center justify-center text-gray-600 mb-5">
                Our Therapeutic Diet Plan offers a comprehensive guide on
                dietary dos and don'ts tailored to your health needs. This plan
                is designed to support various health conditions and help you
                achieve your wellness goals with a structured approach.
              </p>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 mb-4">
                <span>₹1500</span>
              </h1>
              <Link to="/appointment">
                <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
                  Book Consultation
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="p-4  w-full">
            <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
              <h2 className="text-2xl tracking-widest title-font mb-1 font-bold flex align-middle justify-center">
                Weight Loss Packages
              </h2>
              <h2 className="text-md tracking-widest title-font font-normal flex align-middle justify-center mb-5">
                One-time Consultation
              </h2>
              <p className="flex items-center justify-center text-gray-600 mb-5">
                Our Weight Loss Packages are customized based on your current
                weight and specific goals. Choose from a range of packages
                designed to help you achieve effective and sustainable weight
                loss with personalized support and guidance.
              </p>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center pb-4 mb-4">
                <span>Price vary according to the conditions</span>
              </h1>
              <Link to="/appointment">
                <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
                  Book Consultation
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Table />
        <Faq />
      </div>
    </>
  );
};

export default Prices;
