import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="container px-5 py-4 mx-auto">
      <div className="bg-[#f7f7f7] flex flex-col md:flex-row items-center justify-between w-full px-20 py-10 relative">
        <div className="md:w-3/4 z-[1]">
          <h1 className="text-xl text-left font-semibold mb-2 text-gray-900 md:text-left">
            We Are Proud of Our Achievements
          </h1>
          <p className="w-full sm:text-center">
            But our ultimate satisfaction comes from seeing our clients achieve
            their goals and live healthier, happier lives. Join Dietician
            Priyanka today and embark on your own transformative journey towards
            optimal health and well-being.
          </p>
        </div>
        <div className="mt-4 md:mt-0 z-[1]">
          <Link to="/appointment">
            <button className="inline-flex items-center bg-[#0A7CEA] hover:bg-[#0D4372]  text-white border-0 py-1 px-3 focus:outline-none  rounded text-base">
              Book an Appointment
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
