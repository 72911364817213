import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const DietPlanPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const topics = [
    {
      title: "Intermediate Fasting Diet",
      image: "/img/Diet-plan/d1.jpg",
      description:
        "Intermediate fasting involves cycling between periods of eating and fasting. It aims to improve metabolic health, aid in weight loss, and support cellular repair. Common methods include the 16/8 method (16 hours of fasting with an 8-hour eating window) and the 5:2 diet (eating normally for 5 days and restricting calories for 2 days). Emphasis is on consuming balanced meals during eating periods.",
      action: "Join Now",
    },
    {
      title: "Paleo Diet",
      image: "/img/Diet-plan/d2.jpg",
      description:
        "The Paleo diet focuses on eating whole foods that were presumably available to our Paleolithic ancestors. This includes lean meats, fish, fruits, vegetables, nuts, and seeds while excluding processed foods, grains, dairy, and legumes. The diet aims to improve health by consuming nutrient-dense foods and avoiding modern processed foods.",
      action: "Join Now",
    },
    {
      title: "Low Carb Diet",
      image: "/img/Diet-plan/d3.jpg",
      description:
        "A low carb diet limits carbohydrates, primarily found in sugary foods, pasta, and bread. Instead, it emphasizes foods high in protein and fat. There are various types of low carb diets, including the ketogenic diet, which is extremely low in carbs. The diet aims to reduce insulin levels, support weight loss, and improve metabolic health.",
      action: "Join Now",
    },
    {
      title: "Mediterranean Diet",
      image: "/img/Diet-plan/d4.jpg",
      description:
        "The Mediterranean diet is based on traditional foods that people used to eat in countries like Italy and Greece. It emphasizes whole grains, fruits, vegetables, legumes, nuts, olive oil, and fish. Red meat is limited, and wine is consumed in moderation. The diet is associated with numerous health benefits, including improved heart health and reduced risk of chronic diseases.",
      action: "Join Now",
    },
    {
      title: "Vegan Diet",
      image: "/img/Diet-plan/d5.jpg",
      description:
        "A vegan diet excludes all animal products, including meat, dairy, and eggs. Instead, it focuses on plant-based foods like fruits, vegetables, grains, nuts, and seeds. The diet aims to promote animal welfare, environmental sustainability, and health benefits such as improved heart health, weight loss, and reduced risk of chronic diseases.",
      action: "Join Now",
    },
    {
      title: "DASH Diet",
      image: "/img/Diet-plan/d6.jpg",
      description:
        "The DASH (Dietary Approaches to Stop Hypertension) diet is designed to combat high blood pressure. It emphasizes fruits, vegetables, whole grains, lean proteins, and low-fat dairy while limiting salt, red meat, sweets, and sugary beverages. The diet aims to reduce blood pressure, improve heart health, and promote overall well-being.",
      action: "Join Now",
    },
    {
      title: "Keto Diet",
      image: "/img/Diet-plan/d7.jpg",
      description:
        "The ketogenic diet is a very low-carb, high-fat diet that involves drastically reducing carbohydrate intake and replacing it with fat. This reduction in carbs puts the body into a metabolic state called ketosis. The diet aims to support weight loss, improve metabolic health, and provide benefits for certain neurological conditions.",
      action: "Join Now",
    },
    {
      title: "Flexitarian Diet",
      image: "/img/Diet-plan/d8.jpg",
      description:
        "The flexitarian diet is primarily a plant-based diet with the occasional inclusion of meat and animal products. It emphasizes fruits, vegetables, whole grains, and plant-based proteins while allowing flexibility for moderate meat consumption. The diet aims to offer the benefits of a vegetarian diet while providing flexibility for individual preferences.",
      action: "Join Now",
    },
    {
      title: "Fad Diet",
      image: "/img/Diet-plan/d9.jpg",
      description:
        "Fad diets are trendy diets that promise rapid weight loss and other health benefits. They often involve extreme food restrictions or specific combinations of foods. While some fad diets may offer short-term results, they are typically not sustainable and may lack essential nutrients. It's important to approach fad diets with caution and prioritize balanced nutrition.",
      action: "Join Now",
    },
    {
      title: "Plant-Based Diet",
      image: "/img/Diet-plan/d10.jpg",
      description:
        "A plant-based diet focuses on foods derived from plants, including fruits, vegetables, nuts, seeds, oils, whole grains, legumes, and beans. It minimizes or eliminates animal products. The diet aims to improve health by increasing the intake of nutrient-dense, whole foods and reducing the risk of chronic diseases.",
      action: "Join Now",
    },
    {
      title: "Whole30 Diet",
      image: "/img/Diet-plan/d11.jpg",
      description:
        "The Whole30 diet is a 30-day program that emphasizes whole foods and eliminates sugar, alcohol, grains, legumes, soy, and dairy. It aims to reset eating habits, eliminate cravings, and identify food sensitivities. The diet focuses on consuming vegetables, fruits, nuts, seeds, and high-quality proteins.",
      action: "Join Now",
    },
    {
      title: "Weight Watchers Diet",
      image: "/img/Diet-plan/d12.jpg",
      description:
        "The Weight Watchers diet, now known as WW, uses a point system to help individuals make healthier food choices and manage portions. Foods are assigned point values based on their nutritional content, and individuals are allotted a certain number of points per day. The diet aims to support weight loss through balanced eating and lifestyle changes.",
      action: "Join Now",
    },
    {
      title: "Low Fat Diet",
      image: "/img/Diet-plan/d13.jpg",
      description:
        "A low-fat diet focuses on reducing the intake of fat, particularly saturated and trans fats. It emphasizes fruits, vegetables, whole grains, lean proteins, and low-fat dairy products. The diet aims to support heart health, weight loss, and overall well-being by limiting fat consumption.",
      action: "Join Now",
    },
    {
      title: "Atkins Diet",
      image: "/img/Diet-plan/d14.jpg",
      description:
        "The Atkins diet is a low-carb diet that focuses on reducing carbohydrate intake while increasing protein and fat consumption. It involves several phases, starting with very low carbs and gradually increasing carb intake. The diet aims to support weight loss, improve metabolic health, and reduce the risk of chronic diseases.",
      action: "Join Now",
    },
    {
      title: "Dukan Diet",
      image: "/img/Diet-plan/d15.jpg",
      description:
        "The Dukan diet is a high-protein, low-carb diet that involves four phases: Attack, Cruise, Consolidation, and Stabilization. Each phase has specific guidelines for food intake. The diet aims to support rapid weight loss and long-term weight management by emphasizing protein and minimizing carbs.",
      action: "Join Now",
    },
    {
      title: "Heart Healthy Diet",
      image: "/img/Diet-plan/d16.jpg",
      description:
        "A heart healthy diet focuses on foods that promote cardiovascular health. This includes fruits, vegetables, whole grains, lean proteins, and healthy fats like those found in fish and nuts. The diet aims to reduce the risk of heart disease, manage hypertension, and support recovery post cardiac surgery by limiting salt, sugar, and unhealthy fats.",
      action: "Join Now",
    },
    {
      title: "Diabetic Diet",
      image: "/img/Diet-plan/d17.jpg",
      description:
        "A diabetic diet aims to manage blood sugar levels for individuals with type 1 or type 2 diabetes, pre-diabetes, or insulin resistance. It emphasizes balanced meals with complex carbs, lean proteins, and healthy fats while avoiding refined sugars and simple carbs. The diet supports stable blood glucose levels and overall health.",
      action: "Join Now",
    },
    {
      title: "Renal Diet",
      image: "/img/Diet-plan/d18.jpg",
      description:
        "A renal diet is designed for individuals with chronic kidney disease or kidney stones. It focuses on limiting sodium, potassium, and phosphorus intake while providing adequate protein. The diet aims to reduce the burden on the kidneys, manage electrolyte levels, and support overall kidney function.",
      action: "Join Now",
    },
    {
      title: "Low Sodium Diet",
      image: "/img/Diet-plan/d19.jpg",
      description:
        "A low sodium diet is essential for managing hypertension, heart failure, and liver cirrhosis. It limits the intake of salt and sodium-rich foods, emphasizing fresh fruits, vegetables, lean proteins, and whole grains. The diet aims to reduce fluid retention, lower blood pressure, and improve overall heart health.",
      action: "Join Now",
    },
    {
      title: "Gluten Free Diet",
      image: "/img/Diet-plan/d20.jpg",
      description:
        "A gluten free diet is necessary for individuals with celiac disease, gluten sensitivity, or wheat allergy. It excludes all foods containing gluten, such as wheat, barley, and rye. The diet emphasizes gluten-free grains, fruits, vegetables, and lean proteins. It aims to prevent symptoms and promote gut health.",
      action: "Join Now",
    },
    {
      title: "Low Residue Diet",
      image: "/img/Diet-plan/d21.jpg",
      description:
        "A low residue diet is used for conditions like Crohn's disease and ulcerative colitis. It reduces fiber intake to minimize bowel movements and ease digestive symptoms. The diet includes low-fiber foods such as white bread, refined cereals, and cooked vegetables, aiming to reduce digestive strain and promote comfort.",
      action: "Join Now",
    },
    {
      title: "High Fiber Diet",
      image: "/img/Diet-plan/d22.jpg",
      description:
        "A high fiber diet is beneficial for managing constipation. It includes foods rich in dietary fiber such as fruits, vegetables, whole grains, and legumes. The diet aims to promote regular bowel movements, improve digestive health, and support overall well-being by increasing fiber intake.",
      action: "Join Now",
    },
    {
      title: "High Calorie Diet",
      image: "/img/Diet-plan/d23.jpg",
      description:
        "A high calorie diet is designed for individuals needing extra energy, such as those with high metabolic demands or underweight. It includes calorie-dense foods like nuts, seeds, dairy products, and healthy fats. The diet aims to support weight gain, energy levels, and overall health.",
      action: "Join Now",
    },
    {
      title: "High Protein Diet",
      image: "/img/Diet-plan/d24.jpg",
      description:
        "A high protein diet is beneficial for individuals with malnutrition, certain infections, wound healing, or muscle wasting. It includes lean meats, fish, dairy products, beans, and legumes. The diet aims to support muscle repair, immune function, and overall health by increasing protein intake.",
      action: "Join Now",
    },
    {
      title: "Lactose Free Diet",
      image: "/img/Diet-plan/d25.jpg",
      description:
        "A lactose free diet is essential for individuals with lactose intolerance. It eliminates dairy products containing lactose, focusing on lactose-free alternatives like almond milk, soy milk, and lactose-free dairy products. The diet aims to prevent symptoms and promote digestive comfort.",
      action: "Join Now",
    },
    {
      title: "Detox Diet",
      image: "/img/Diet-plan/d26.jpg",
      description:
        "A detox diet focuses on eliminating toxins from the body by consuming natural, whole foods and beverages. It often includes fruits, vegetables, herbal teas, and water while avoiding processed foods and additives. The diet aims to cleanse the body, boost energy, and promote overall health.",
      action: "Join Now",
    },
    {
      title: "Cancer Diet",
      image: "/img/Diet-plan/d27.jpg",
      description:
        "A cancer diet, including the neutropenic diet, supports individuals during and after cancer treatment. It focuses on nutrient-dense foods that support immune function, such as lean proteins, whole grains, fruits, and vegetables. The diet aims to maintain strength, promote healing, and support overall well-being.",
      action: "Join Now",
    },
    {
      title: "Ayurvedic Diet",
      image: "/img/Diet-plan/d28.jpg",
      description:
        "The Ayurvedic diet is based on principles from Ayurvedic medicine, emphasizing balance among body, mind, and spirit. It includes whole, natural foods tailored to an individual's dosha (body type). The diet aims to promote health, prevent disease, and maintain balance through personalized nutrition.",
      action: "Join Now",
    },
    {
      title: "Diet for Mental Disorder and Anxiety",
      image: "/img/Diet-plan/d29.jpg",
      description:
        "A diet for mental disorders and anxiety focuses on consuming foods that support brain health and reduce stress. It includes nutrient-rich foods like fruits, vegetables, whole grains, and lean proteins. This diet aims to promote mental well-being, reduce anxiety, and support overall mental health through balanced nutrition and mindful eating practices.",
      action: "Join Now",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Diet Plan | Dietician Priyanka</title>
      </Helmet>
      <div className="mt-20 container mx-auto px-5">
        <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
          <div className="absolute inset-0 top-0 flex items-start justify-center">
            <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
          </div>
          <div className="z-[1]">
            <img src="T-logo.png" alt="" />
          </div>
          <div className="flex flex-col gap-4 items-center z-[1]">
            <div>
              <h1 className="text-2xl font-semibold">
                Diet Plans for Specific Health Conditions
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {topics.map((topic, index) => (
              <motion.div
                key={index}
                className="bg-gray-50 rounded-lg overflow-hidden shadow-md"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={topic.image}
                  alt={topic.title}
                  className="w-full h-72 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    {topic.title}
                  </h2>
                  <p className="text-gray-600 mb-4">{topic.description}</p>
                  {/* <Link to="/contact">
                    <button className="bg-[#0F4B7C] text-white px-4 py-2 rounded text-lg hover:bg-[#0A7CEA] transition duration-300">
                      {topic.action}
                    </button>
                  </Link> */}
                  <a
                    href="https://wa.me/8130548921?text=Thank%20you%20for%20contacting%20Dietician%20Priyanka%20Singh%21%20Our%20representative%20will%20get%20in%20touch%20with%20you%20shortly.%20To%20know%20more%20about%20us%2C%20kindly%20have%20a%20look%20at%20our%20website%20www.dieticianpriyanka.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Chat with us on WhatsApp"
                  >
                    <button className="bg-[#0F4B7C] text-white px-4 py-2 rounded text-lg hover:bg-[#0A7CEA] transition duration-300">
                      {topic.action}
                    </button>
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DietPlanPage;
