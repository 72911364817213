import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const HeartDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a Heart Healthy Diet?",
      answer:
        "A Heart Healthy Diet focuses on consuming foods that improve cardiovascular health, lower cholesterol, and reduce the risk of heart disease. This includes a balance of fruits, vegetables, whole grains, lean proteins, and healthy fats.",
    },
    {
      question: "What are the benefits of a Heart Healthy Diet?",
      answer:
        "Benefits include reduced risk of heart disease, lower blood pressure, improved cholesterol levels, better weight management, and overall enhanced cardiovascular health.",
    },
    {
      question: "What foods should I avoid on a Heart Healthy Diet?",
      answer:
        "Avoid foods high in saturated and trans fats, sodium, and added sugars. This includes processed foods, fried foods, sugary drinks, and high-fat dairy products.",
    },
    {
      question: "How can I incorporate a Heart Healthy Diet into my lifestyle?",
      answer:
        "Incorporate a variety of fruits, vegetables, whole grains, lean proteins, and healthy fats into your meals. Plan balanced meals, stay hydrated, and engage in regular physical activity.",
    },
    {
      question: "Can a Heart Healthy Diet help with existing heart conditions?",
      answer:
        "Yes, a Heart Healthy Diet can help manage existing heart conditions by improving cholesterol levels, lowering blood pressure, and promoting overall heart health. Always consult with your healthcare provider for personalized advice.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Heart Healthy Diet - Benefits, Foods to Avoid, and Tips</title>
        <meta
          name="description"
          content="Learn about the Heart Healthy Diet, including its benefits, foods to avoid, and tips to help improve cardiovascular health."
        />
        <meta
          name="keywords"
          content="heart healthy diet, cardiovascular health, nutrition, heart disease prevention"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Heart Healthy Diet</h1>
            <p className="mt-2 text-md">
              Discover the benefits and guidelines of a Heart Healthy Diet to
              improve your cardiovascular health.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          {/* Benefits Section */}
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Benefits
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/1.jpg"
                alt="Benefits of Heart Healthy Diet"
                className="w-full h-96 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                A Heart Healthy Diet offers numerous benefits, including reduced
                risk of heart disease, lower blood pressure, and improved
                cholesterol levels. It can also help with better weight
                management and overall cardiovascular health.
              </p>
              <p className="mb-4">
                By following a structured diet, you can support your heart
                health and enjoy a healthier lifestyle.
              </p>
            </div>
          </section>

          {/* Foods to Avoid Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Foods to Avoid
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/2.jpg"
                alt="Foods to Avoid in Heart Healthy Diet"
                className="w-full h-96 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                On a Heart Healthy Diet, it's essential to avoid foods high in
                saturated and trans fats, sodium, and added sugars. These can
                increase the risk of heart disease and negatively impact heart
                health.
              </p>
              <p className="mb-4">
                Instead, focus on consuming a variety of whole, natural foods
                that support cardiovascular health.
              </p>
            </div>
          </section>

          {/* Tips Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Tips
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/programFeatures.jpg"
                alt="Tips for Heart Healthy Diet"
                className="w-full h-64 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                Incorporating a Heart Healthy Diet into your lifestyle involves
                planning balanced meals with a variety of fruits, vegetables,
                whole grains, lean proteins, and healthy fats. Regular physical
                activity and hydration are also crucial.
              </p>
              <p className="mb-4">
                Consistency and adherence to healthy eating habits are key to
                achieving the best results.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default HeartDiet;
