import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const ChildHealthDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What are the key components of a healthy diet for children?",
      answer:
        "A healthy diet for children includes a balance of proteins, carbohydrates, healthy fats, vitamins, and minerals. Key components are fruits, vegetables, whole grains, lean proteins, and dairy. Limiting processed foods, sugars, and unhealthy fats is also crucial.",
    },
    {
      question: "How can I manage my child's weight effectively?",
      answer:
        "To manage your child's weight, focus on a balanced diet and regular physical activity. Encourage healthy eating habits, limit sugary and high-calorie snacks, and involve your child in physical activities they enjoy. Avoid dieting and focus on healthy lifestyle changes.",
    },
    {
      question:
        "What role does physical activity play in childhood obesity prevention?",
      answer:
        "Physical activity is essential for maintaining a healthy weight and preventing obesity. It helps burn calories, build muscle, and support overall physical health. Aim for at least 1 hour of moderate to vigorous physical activity daily for children.",
    },
    {
      question: "How can I make healthy eating fun for my child?",
      answer:
        "Making healthy eating fun can involve colorful and creative meal presentations, involving your child in meal planning and preparation, and introducing new foods in a positive and non-coercive way. Use fun shapes and engaging recipes to make healthy foods appealing.",
    },
    {
      question: "When should I seek professional help for my child's weight?",
      answer:
        "Seek professional help if your child is significantly overweight or obese, or if you have concerns about their eating habits or overall health. A healthcare provider or Dietician Priyanka can provide personalized advice and support for managing your child's weight.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Child Health Diet - Managing Obesity and Nutrition</title>
        <meta
          name="description"
          content="Explore our Child Health Diet program focusing on obesity management and nutrition for children. Get expert advice on healthy eating and lifestyle changes."
        />
        <meta
          name="keywords"
          content="child health, diet for children, obesity management, nutrition, healthy eating"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Child Health Diet</h1>
            <p className="mt-2 text-md">
              Expert guidance on managing obesity and ensuring a balanced diet
              for children.
            </p>
          </div>
        </header>

        <section className="container mx-auto py-12 px-6 lg:px-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 justify-center">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">1800+</p>
                <p className="mt-2 text-gray-600">Healthy Kids</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">92%</p>
                <p className="mt-2 text-gray-600">Parent Satisfaction Rate</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">250+</p>
                <p className="mt-2 text-gray-600">Customized Meal Plans</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg py-4 px-8 mb-12 animate__animated animate__fadeInUp">
            <img
              src="/img/child.jpg"
              alt="Healthy Child"
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-bold mb-4">Ideal for</h3>
            <p className="mb-4">
              Our Child Health Diet program is designed for parents aiming to
              manage their child's weight and improve their overall nutrition.
              We offer personalized meal plans, expert advice, and strategies to
              support a healthy lifestyle.
            </p>
            <h4 className="text-xl font-semibold mb-4">Balanced Nutrition</h4>
            <p className="mb-4">
              Ensuring a balanced diet is key to managing obesity and supporting
              healthy growth. Our program provides tailored meal plans that
              include a variety of nutritious foods to meet your child's needs.
            </p>
            <h4 className="text-xl font-semibold mb-4">
              Healthy Eating Habits
            </h4>
            <p className="mb-4">
              We help develop healthy eating habits by focusing on whole foods,
              portion control, and reducing the intake of processed and sugary
              foods. Encouraging mindful eating can also play a significant
              role.
            </p>
            <img
              src="/img/food.jpg"
              alt="Healthy Eating"
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
            <h4 className="text-xl font-semibold mb-4">Physical Activity</h4>
            <p className="mb-4">
              Regular physical activity is essential for maintaining a healthy
              weight. We provide guidance on incorporating fun and engaging
              activities that encourage your child to stay active.
            </p>
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Customized meal plans tailored to your child's nutritional
                needs.
              </li>
              <li className="mb-2">
                Expert guidance on managing obesity and promoting healthy
                eating.
              </li>
              <li className="mb-2">
                Strategies for encouraging physical activity and reducing
                sedentary behavior.
              </li>
              <li className="mb-2">
                Regular follow-ups to monitor progress and adjust plans as
                needed.
              </li>
              <li className="mb-2">
                Supportive resources and a community for ongoing motivation and
                advice.
              </li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Program Features"
              className="w-full h-96 object-cover rounded-lg"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChildHealthDiet;
