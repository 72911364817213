import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const ArthritisAndBoneHealthDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "How can diet help with arthritis management?",
      answer:
        "A diet rich in anti-inflammatory foods can help manage arthritis symptoms. Foods high in omega-3 fatty acids, such as fatty fish, and antioxidants found in fruits and vegetables can reduce inflammation and improve joint health.",
    },
    {
      question: "What foods are best for bone health?",
      answer:
        "Foods rich in calcium and vitamin D are essential for bone health. Dairy products, leafy greens, and fortified foods provide calcium, while sunlight exposure and foods like fatty fish and fortified cereals offer vitamin D.",
    },
    {
      question: "How can I plan my meals to support joint and bone health?",
      answer:
        "Incorporate a variety of nutrient-dense foods into your meals, focusing on those that support bone and joint health. Include sources of calcium, vitamin D, and omega-3 fatty acids in your diet. Planning meals with these nutrients in mind can help maintain healthy bones and joints.",
    },
    {
      question:
        "What lifestyle changes can complement a diet for arthritis and bone health?",
      answer:
        "Regular physical activity, maintaining a healthy weight, and avoiding smoking can complement a diet aimed at improving arthritis and bone health. Weight-bearing exercises and strength training can help improve bone density and joint function.",
    },
    {
      question:
        "When should I consult a healthcare professional about my diet for arthritis and bone health?",
      answer:
        "If you experience persistent or worsening symptoms, it’s a good idea to consult a healthcare professional. They can provide personalized dietary advice and help address specific concerns related to arthritis and bone health.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Arthritis and Bone Health Diet - Improve Joint and Bone Health
        </title>
        <meta
          name="description"
          content="Explore dietary tips for managing arthritis and supporting bone health. Learn about foods that reduce inflammation and improve joint and bone health."
        />
        <meta
          name="keywords"
          content="arthritis diet, bone health, anti-inflammatory foods, calcium, vitamin D, joint health"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">
              Arthritis and Bone Health Diet
            </h1>
            <p className="mt-2 text-md">
              Learn how to manage arthritis and support bone health with a
              balanced diet.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          {/* Diet for Arthritis Section */}
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Diet for Arthritis
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/food.jpg"
                alt="Diet for Arthritis"
                className="w-full h-full object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                A diet rich in anti-inflammatory foods can help manage arthritis
                symptoms. Focus on incorporating foods high in omega-3 fatty
                acids, such as salmon and walnuts, as well as antioxidants found
                in berries, spinach, and other colorful vegetables.
              </p>
              <p className="mb-4">
                Reducing your intake of processed foods and sugars can also help
                manage inflammation and improve joint function. Regularly
                consuming these foods can support overall joint health and
                alleviate symptoms.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* Bone Health Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Bone Health
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/bone_health.jpg"
                alt="Bone Health"
                className="w-full h-full object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                Supporting bone health involves consuming foods rich in calcium
                and vitamin D. Dairy products, leafy greens, and fortified
                plant-based milks are excellent sources of calcium. For vitamin
                D, include fatty fish, egg yolks, and fortified cereals in your
                diet.
              </p>
              <p className="mb-4">
                Regular physical activity, especially weight-bearing exercises,
                also plays a crucial role in maintaining bone density and
                strength. Combining a balanced diet with exercise can help
                prevent bone loss and support overall bone health.
              </p>
            </div>
          </section>

          {/* Lifestyle Changes Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Complementary Lifestyle Changes
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/services.jpg"
                alt="Balanced Diet"
                className="w-full h-full object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                In addition to a healthy diet, maintaining a healthy weight and
                engaging in regular exercise are important for managing
                arthritis and supporting bone health. Weight-bearing exercises
                like walking and strength training can enhance bone density and
                joint function.
              </p>
              <p className="mb-4">
                Avoid smoking and limit alcohol consumption, as these can
                negatively impact bone health. By adopting these lifestyle
                changes, you can complement your diet and promote better joint
                and bone health.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ArthritisAndBoneHealthDiet;
