import React from "react";

const cards = [
  {
    title: "Experience",
    image: "/img/Experience.jpeg",
    points: [
      "10+ years in the field",
      "Certified by top institutions",
      "Specialized in various diets",
    ],
  },
  {
    title: "Approach",
    image: "/img/Approach.jpg",
    points: ["Personalized plans", "Holistic health", "Evidence-based methods"],
  },
  {
    title: "Ethics",
    image: "/img/Ethics.jpeg",
    points: ["Transparency", "Client-first policy", "Confidentiality"],
  },
  {
    title: "Client Care",
    image: "/img/clientCare.png",
    points: ["24/7 support", "Regular follow-ups", "Comprehensive assessments"],
  },
];

const DietitianPriyanka = () => {
  return (
    <div className="p-6">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">
          Why Dietician Priyanka?
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          Get personalized diet plans and dietician advice from an experienced
          Dietician.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 ">
        {cards.map((card) => (
          <div
            key={card.title}
            className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
          >
            <img
              src={card.image}
              alt={card.title}
              className="w-full h-36 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              {card.title}
            </h2>
            <ul className="list-disc pl-5 text-gray-600">
              {card.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DietitianPriyanka;
