import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const PregnancyAndLactationDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question:
        "What are the essential nutrients during pregnancy and lactation?",
      answer:
        "Essential nutrients during pregnancy and lactation include folic acid, iron, calcium, vitamin D, DHA, and iodine. These nutrients support the development of the baby and the health of the mother.",
    },
    {
      question: "Is exercise safe during pregnancy?",
      answer:
        "Yes, exercise is safe and beneficial during pregnancy. However, it's important to choose safe and appropriate exercises, such as walking, swimming, and prenatal yoga. Always consult your healthcare provider before starting any new exercise routine.",
    },
    {
      question: "How can I manage morning sickness during pregnancy?",
      answer:
        "To manage morning sickness, try eating small, frequent meals, staying hydrated, avoiding strong smells, and resting when needed. Ginger and vitamin B6 supplements may also help, but consult your healthcare provider before taking any supplements.",
    },
    {
      question: "What foods should I avoid during pregnancy?",
      answer:
        "Avoid raw or undercooked meat, fish high in mercury, unpasteurized dairy products, and raw eggs. It's also important to limit caffeine intake and avoid alcohol.",
    },
    {
      question: "How can I prepare for breastfeeding?",
      answer:
        "Preparing for breastfeeding involves attending lactation classes, learning about proper latching techniques, and creating a comfortable feeding environment. Consulting with a lactation consultant can also be very helpful.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pregnancy and Lactation Diet - Nutrition for Mom and Baby</title>
        <meta
          name="description"
          content="Get expert support and personalized care for a healthy pregnancy and lactation period. Our program offers guidance on nutrition, exercise, and wellness."
        />
        <meta
          name="keywords"
          content="pregnancy diet, lactation diet, nutrition, exercise, wellness during pregnancy, breastfeeding support"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">
              Pregnancy and Lactation Diet
            </h1>
            <p className="mt-2 text-md">
              Expert support for a healthy pregnancy and breastfeeding journey.
            </p>
          </div>
        </header>

        <section className="container mx-auto py-12 px-6 lg:px-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 justify-center">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">1000+</p>
                <p className="mt-2 text-gray-600">Happy Moms</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">98%</p>
                <p className="mt-2 text-gray-600">Satisfaction Rate</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">50+</p>
                <p className="mt-2 text-gray-600">Health Workshops</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <h2 className="text-3xl font-bold mb-4">Pre-Pregnancy Nutrition</h2>
            <p className="mb-4">
              Proper nutrition before pregnancy helps ensure a healthy start.
              Our program offers tailored advice on essential nutrients to
              prepare your body for pregnancy.
            </p>
            {/* <img
              src="/img/pregna.jpg"
              alt="Pre-Pregnancy Nutrition"
              className="w-full h-96 object-cover rounded-lg mb-6"
            /> */}
            <p className="mb-4">
              Key nutrients include folic acid to prevent birth defects, iron to
              boost energy levels, and calcium for bone health.
            </p>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <h2 className="text-3xl font-bold mb-4">During Pregnancy</h2>
            <p className="mb-4">
              Our program provides guidance on maintaining a balanced diet
              throughout pregnancy to support both mother and baby's health.
            </p>
            <img
              src="/img/pregna.jpg"
              alt="During Pregnancy"
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
            <h4 className="text-xl font-semibold mb-4">
              Dietician During Pregnancy
            </h4>
            <p className="mb-4">
              Proper diet is crucial during pregnancy. We provide personalized
              meal plans that include all essential nutrients to support your
              baby's development.
            </p>
            <h4 className="text-xl font-semibold mb-4">
              Safe Exercise Routines
            </h4>
            <p className="mb-4">
              Regular exercise can help you maintain your health during
              pregnancy. We offer safe and effective routines tailored to your
              stage of pregnancy.
            </p>
            <h4 className="text-xl font-semibold mb-4">Emotional Support</h4>
            <p className="mb-4">
              Pregnancy can be an emotional journey. Our program provides
              emotional support to help you manage stress and enjoy a healthy
              pregnancy.
            </p>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <h2 className="text-3xl font-bold mb-4">Lactation Support</h2>
            <p className="mb-4">
              Our lactation support program helps ensure a smooth breastfeeding
              experience with personalized advice and guidance.
            </p>
            {/* <img
              src="/img/pregna.jpg"
              alt="Lactation Support"
              className="w-full h-96 object-cover rounded-lg mb-6"
            /> */}
            <h4 className="text-xl font-semibold mb-4">
              Preparing for Breastfeeding
            </h4>
            <p className="mb-4">
              Preparing for breastfeeding involves learning about proper
              latching techniques and creating a comfortable feeding
              environment. We offer lactation classes and consultations to
              assist you.
            </p>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Personalized care plans for pre-pregnancy, pregnancy, and
                lactation.
              </li>
              <li className="mb-2">
                Expert guidance on nutrition, exercise, and wellness.
              </li>
              <li className="mb-2">
                Regular check-ins and support for you and your baby.
              </li>
              <li className="mb-2">
                Access to educational resources and workshops.
              </li>
              <li className="mb-2">Emotional support and counseling.</li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Program Features"
              className="w-full h-96 object-cover rounded-lg"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PregnancyAndLactationDiet;
