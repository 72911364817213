import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const DietForLowImmunity = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "How can a diet improve my immune system?",
      answer:
        "A diet rich in vitamins, minerals, and antioxidants can help strengthen your immune system. Key nutrients include vitamin C, vitamin D, zinc, and antioxidants from fruits and vegetables. Eating a balanced diet supports your body's ability to fight infections and stay healthy.",
    },
    {
      question: "What foods are best for boosting immunity?",
      answer:
        "Foods that are particularly beneficial for boosting immunity include citrus fruits (for vitamin C), nuts and seeds (for vitamin E and zinc), garlic and ginger (for their anti-inflammatory properties), and yogurt (for probiotics). Incorporating these into your diet can help enhance immune function.",
    },
    {
      question: "How can I plan my meals to support my immune system?",
      answer:
        "Include a variety of nutrient-dense foods in your meals. Focus on fruits and vegetables, lean proteins, whole grains, and healthy fats. Ensure you are getting a mix of immune-boosting vitamins and minerals. Meal planning ahead of time can help you stay on track with a diet that supports immune health.",
    },
    {
      question:
        "What lifestyle changes can complement a diet for better immunity?",
      answer:
        "In addition to a healthy diet, regular exercise, adequate sleep, and stress management play crucial roles in maintaining a strong immune system. Combine a balanced diet with these lifestyle changes for optimal immune support.",
    },
    {
      question: "When should I seek professional advice for my immune health?",
      answer:
        "If you have persistent or frequent infections, fatigue, or other health concerns related to your immune system, it’s advisable to seek professional guidance. A healthcare provider or Dietician Priyanka can offer personalized advice and help address specific dietary needs for improving immunity.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Diet for Low Immunity - Boost Your Immune System</title>
        <meta
          name="description"
          content="Explore dietary tips and guidelines to boost your immune system. Learn about foods that support immune health and how to plan meals for better immunity."
        />
        <meta
          name="keywords"
          content="diet for low immunity, immune system, healthy eating, nutrition, immune-boosting foods"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Diet for Low Immunity</h1>
            <p className="mt-2 text-md">
              Enhance your immune system with a balanced diet.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Why a Strong Immune System Matters
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/services.jpg"
                alt="Balanced Diet"
                className="w-full h-full object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                A strong immune system is essential for defending your body
                against infections and illnesses. Proper nutrition plays a
                crucial role in supporting and enhancing immune function. By
                eating the right foods, you can help your body maintain a robust
                immune system.
              </p>
              <p className="mb-4">
                Key components of an immune-boosting diet include a variety of
                fruits and vegetables, lean proteins, whole grains, and healthy
                fats. These foods provide the necessary vitamins and minerals
                that support immune health and overall well-being.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold text-center mb-6 animate__animated animate__fadeInUp">
              Meal Planning Tips for Immunity
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <h3 className="text-2xl font-bold mb-4">
                Creating a Diet That Supports Immune Health
              </h3>
              <p className="mb-4">
                When planning meals to support your immune system, aim to
                include a variety of nutrient-dense foods. A balanced meal for
                immunity should consist of:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li className="mb-2">
                  Protein: Lean meats, fish, tofu, or legumes.
                </li>
                <li className="mb-2">
                  Carbohydrates: Whole grains like brown rice, quinoa, or whole
                  wheat bread.
                </li>
                <li className="mb-2">
                  Fruits and Vegetables: A mix of colorful vegetables and fruits
                  rich in vitamins and antioxidants.
                </li>
                <li className="mb-2">
                  Healthy Fats: Avocados, nuts, seeds, and olive oil.
                </li>
              </ul>
              <p className="mb-4">
                Plan your meals ahead to ensure you’re including all the
                essential nutrients that support immune function. This can help
                you make healthier choices and avoid nutrient deficiencies.
              </p>
              <img
                src="/img/meal.jpg"
                alt="Meal Planning"
                className="w-full h-full object-cover rounded-lg mt-6"
              />
            </div>
          </section>

          <section className=" mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Benefits of a Diet for Low Immunity
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <ul className="list-disc pl-5 mb-4">
                <li className="mb-2">
                  Improved immune response and defense against infections.
                </li>
                <li className="mb-2">
                  Enhanced energy levels and overall vitality.
                </li>
                <li className="mb-2">
                  Better management of stress and inflammation.
                </li>
                <li className="mb-2">
                  Reduced risk of illness and improved recovery times.
                </li>
                <li className="mb-2">
                  Overall improvement in health and well-being.
                </li>
              </ul>
              <p className="mb-4">
                Adopting a diet designed to boost immunity can lead to a
                stronger defense system and better health outcomes. It supports
                not only physical health but also contributes to emotional and
                mental wellness.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DietForLowImmunity;
