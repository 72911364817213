import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const UricAcidArthritis = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is Uric Acid Arthritis?",
      answer:
        "Uric Acid Arthritis, also known as gout, is a form of arthritis caused by high levels of uric acid in the blood. This condition leads to sudden and severe pain, redness, and swelling in the joints, commonly affecting the big toe.",
    },
    {
      question: "What are the symptoms of Uric Acid Arthritis?",
      answer:
        "Symptoms of Uric Acid Arthritis include intense joint pain, swelling, redness, and tenderness, especially in the big toe. The affected joint may feel warm and be extremely sensitive to touch.",
    },
    {
      question: "What causes high uric acid levels?",
      answer:
        "High uric acid levels can be caused by factors such as excessive alcohol consumption, consuming foods high in purines (like red meat and seafood), certain medications, and genetic predisposition.",
    },
    {
      question: "How can I manage and prevent Uric Acid Arthritis?",
      answer:
        "Managing Uric Acid Arthritis involves maintaining a healthy diet low in purines, staying hydrated, avoiding alcohol, and taking medications as prescribed by your doctor. Regular exercise and weight management can also help prevent flare-ups.",
    },
    {
      question: "When should I see a doctor for Uric Acid Arthritis?",
      answer:
        "You should see a doctor if you experience symptoms of Uric Acid Arthritis, such as severe joint pain and swelling. Early diagnosis and treatment are crucial to managing symptoms and preventing future attacks.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Uric Acid Arthritis - Symptoms, Causes, and Management</title>
        <meta
          name="description"
          content="Learn about Uric Acid Arthritis, including symptoms, causes, and management strategies to help alleviate discomfort and prevent flare-ups."
        />
        <meta
          name="keywords"
          content="uric acid arthritis, gout, symptoms, causes, management, prevention"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Uric Acid Arthritis</h1>
            <p className="mt-2 text-md">
              Understand Uric Acid Arthritis with our comprehensive guide on
              symptoms, causes, and management.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          {/* Symptoms Section */}
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Symptoms
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/5.jpg"
                alt="Symptoms of Uric Acid Arthritis"
                className="w-full h-116 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                Uric Acid Arthritis often presents with sudden, severe pain in
                the affected joint. The joint may become red, swollen, and
                tender, with a sensation of warmth.
              </p>
              <p className="mb-4">
                Symptoms can occur suddenly and may last for a few days to a
                couple of weeks. It's important to manage these symptoms to
                prevent future attacks.
              </p>
            </div>
          </section>

          {/* Causes Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Causes
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/6.jpg"
                alt="Causes of Uric Acid Arthritis"
                className="w-full h-96 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                High levels of uric acid in the blood can result from various
                factors, including excessive intake of purine-rich foods,
                alcohol consumption, certain medications, and genetics.
              </p>
              <p className="mb-4">
                Managing dietary choices and lifestyle factors can help control
                uric acid levels and reduce the risk of arthritis flare-ups.
              </p>
            </div>
          </section>

          {/* Management Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Management
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/programFeatures.jpg"
                alt="Management of Uric Acid Arthritis"
                className="w-full h-64 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                Managing Uric Acid Arthritis involves a combination of dietary
                changes, medications, and lifestyle adjustments. Focus on a diet
                low in purines, stay hydrated, and avoid alcohol.
              </p>
              <p className="mb-4">
                Regular exercise, weight management, and adhering to prescribed
                medications can also help manage symptoms and prevent future
                attacks.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default UricAcidArthritis;
