import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import AHero from "../components/about/AHero";
import AContent from "../components/about/AContent";
import Achievement from "../components/about/Achievement";
import Contact from "../components/about/Contact";
import Story from "../components/about/Story";

const About = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <Helmet>
        <title>About Us | Learn More About Dietician Priyanka</title>
      </Helmet>
      <AHero />
      <AContent />
      <Story />
      <Achievement />
      <Contact />
    </>
  );
};

export default About;
