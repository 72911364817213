import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import "animate.css";

const SpecialOffers = () => {
  const [specialOffers, setSpecialOffers] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // Fetch special offers from the backend
    const fetchSpecialOffers = async () => {
      try {
        const response = await axios.get(
          "https://dt-priyanka-1.onrender.com/api/special-offers"
        );
        setSpecialOffers(response.data);
      } catch (error) {
        console.error("Error fetching special offers:", error);
      }
    };

    fetchSpecialOffers();
  }, []); // Run the effect once on component mount

  // Extract promotion and special offer texts
  const promotionText =
    specialOffers.find((item) => item.type === "promotion")?.text || "";
  const specialOfferText =
    specialOffers.find((item) => item.type === "specialOffer")?.text || "";

  useEffect(() => {
    if (specialOfferText) {
      setNotificationText(specialOfferText);
      setShowNotification(true);
    }
  }, [specialOfferText]);

  // Function to close the notification
  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <div className="fixed top-0 w-full p-2 bg-[#0F4B7C] text-white shadow-lg animate__animated animate__fadeIn animate__faster z-50">
          <div className="flex justify-center items-center overflow-hidden">
            <div className="marquee-container flex items-center justify-center w-full">
              <p className="marquee-text text-md font-semibold whitespace-nowrap">
                {notificationText}
              </p>
            </div>
            <button
              onClick={closeNotification}
              className="text-white text-xl focus:outline-none hover:text-gray-300 ml-4"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
      <div className="mt-24 mb-4 mx-4 sm:mx-8 lg:mx-16 shadow-xl border-2 border-[#0F4B7C] p-6 rounded-lg flex flex-col md:flex-row items-center justify-center animate__animated animate__fadeIn animate__faster">
        <div className="flex-1 w-full p-4 md:p-8 text-center md:text-left">
          <h2 className="text-3xl font-bold text-blue-500 mb-4">
            Current Promotions
          </h2>
          <p className="text-black">
            {promotionText || "Stay tuned for current promotions information…"}
          </p>
        </div>
        <div className="flex-shrink-0 mt-6 md:mt-0 sm:p-6">
          <img
            src="T-logo.png"
            alt="logo"
            className="w-32 h-32 sm:w-48 sm:h-48 rounded-full border-4 border-white shadow-lg"
          />
        </div>
        <div className="flex-1 w-full p-4 md:p-8 text-center md:text-left mt-6 md:mt-0">
          <h2 className="text-3xl font-bold text-blue-500 mb-4">
            Special Offers
          </h2>
          <p className="text-black">
            {specialOfferText ||
              "Avail last-minute discounts, early bird offers, long stay offers when you book directly with us."}
          </p>
        </div>
      </div>
    </>
  );
};

export default SpecialOffers;
