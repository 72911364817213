import React from "react";
import "animate.css";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4 mt-28">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8 animate__animated animate__fadeInDown">
        Privacy Policy
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-lg animate__animated animate__fadeInUp">
        <p className="mb-4 text-gray-700">
          Welcome to Dietician Priyanka’s Privacy Policy page. We understand
          your concerns about privacy and are committed to safeguarding your
          personal information. This document outlines how we collect, use, and
          protect your data. Please read it carefully to understand our
          practices.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Information We Collect
        </h2>
        <p className="mb-4 text-gray-700">
          At Dietician Priyanka, we collect personal information such as your
          name, email address, phone number, and dietary preferences. This data
          is essential to provide personalized nutrition advice and health plans
          tailored to your needs.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Use of Information
        </h2>
        <p className="mb-4 text-gray-700">
          The information we collect is used to enhance your experience on our
          website and improve our services. We do not share or sell your
          personal data to third parties. We may, however, use non-personal data
          for statistical analysis to improve our offerings.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Cookies</h2>
        <p className="mb-4 text-gray-700">
          Our website uses cookies to enhance user experience and track website
          performance. You can disable cookies through your browser settings,
          but this may affect the functionality of our site.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Security of Information
        </h2>
        <p className="mb-4 text-gray-700">
          We implement strict security measures to protect your personal data.
          However, please note that no system can be completely secure, and we
          cannot guarantee absolute security of your information.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Changes to This Policy
        </h2>
        <p className="mb-4 text-gray-700">
          We reserve the right to update this Privacy Policy as needed. Any
          changes will be reflected on this page, and your continued use of the
          website constitutes acceptance of such changes.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Contact Us
        </h2>
        <p className="text-gray-700">
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at{" "}
          <a
            href="mailto:priyankadlm@gmail.com"
            className="text-blue-500 underline"
          >
            priyankadlm@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
