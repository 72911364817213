import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "animate.css";

const OnlineYogaClass = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Yoga Programs | Dietician Priyanka</title>
      </Helmet>
      <div className="mt-20 container mx-auto px-5">
        <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative animate__animated animate__fadeIn">
          <div className="absolute inset-0 top-0 flex items-start justify-center">
            <img
              className="h-auto max-w-full"
              src="Abstrac-bg.png"
              alt="Background"
            />
          </div>
          <div className="z-[1]">
            <img src="T-logo.png" alt="Logo" />
          </div>
          <div className="flex flex-col gap-4 items-center z-[1]">
            <div className="text-center">
              <h1 className="text-3xl font-bold animate__animated animate__bounceIn">
                Transform Your Life with Yoga
              </h1>
              <p className="text-lg md:text-xl text-gray-700 mt-4 animate__animated animate__fadeInUp">
                “Discover the benefits of yoga, breathing, and meditation.
                Connect with your inner self and enhance your well-being.”
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-screen p-4 md:p-8">
        <div className="max-w-7xl mx-auto space-y-8">
          <div className="bg-gray-100 rounded-lg shadow-md p-6 transform transition duration-500 hover:scale-105 animate__animated animate__fadeInLeft">
            <div className="flex items-center justify-center mb-4">
              <img
                src="/img/Gyoga.jpg"
                alt="Group Yoga Class"
                className="w-full h-96 object-cover rounded-lg"
              />
            </div>
            <h2 className="text-2xl font-bold mb-4">Dynamic Group Classes</h2>
            <p className="text-gray-700 mb-4">
              Join our dynamic group classes to rejuvenate your mind, body, and
              soul. Achieve your fitness goals with expert guidance.
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700">
              <li>Live & interactive sessions</li>
              <li>Duration: 1 hour</li>
              <li>Personalized attention in small batches</li>
              <li>Comprehensive yoga, stretching, and meditation</li>
              <li>Certified and experienced instructors</li>
              <li>All levels welcome</li>
            </ul>
            <h3 className="text-xl font-semibold mb-2">Available Batches:</h3>
            <ul className="mb-4 text-gray-700">
              <li>
                <strong>Morning (Tue, Thu, Sat):</strong> 6:00 am IST
              </li>
              <li>
                <strong>Morning (Mon-Fri):</strong> 7:00 am IST
              </li>
              <li>
                <strong>Evening (Tue, Thu, Sat):</strong> 6:00 pm IST
              </li>
            </ul>
            <h3 className="text-xl font-semibold mb-2">Fees:</h3>
            <ul className="mb-4 text-gray-700">
              <li>
                <strong>3 days a week:</strong> 2500 INR / per month
              </li>
              <li>
                <strong>5 days a week:</strong> 3500 INR / per month
              </li>
            </ul>
            <div className="text-center mt-4">
              <Link to="/contact">
                <button className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-bold hover:bg-blue-700 transition duration-300">
                  Join Now
                </button>
              </Link>
            </div>
          </div>

          <div className="bg-gray-100 rounded-lg shadow-md p-6 transform transition duration-500 hover:scale-105 animate__animated animate__fadeInRight">
            <div className="flex gap-4 items-center justify-center mb-4 flex-wrap md:flex-nowrap">
              <div className="w-full md:w-1/2 p-2">
                <img
                  src="/img/yoga1.png"
                  alt="Private Yoga Session"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
              <div className="w-full md:w-1/2 p-2">
                <img
                  src="/img/yoga2.jpeg"
                  alt="Private Yoga Session"
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
            </div>
            <h2 className="text-2xl font-bold mb-4">
              Exclusive 1-on-1 Sessions
            </h2>
            <p className="text-gray-700 mb-4">
              Experience personalized yoga sessions tailored to your individual
              needs and goals. Achieve optimal health with one-on-one guidance.
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700">
              <li>1 hour personalized session</li>
              <li>Comprehensive yoga, stretching, and meditation</li>
              <li>Certified and experienced instructors</li>
              <li>All levels welcome</li>
              <li>Per session cost: 1000 INR </li>
              <li>Packages: 12, 16, or 20 sessions</li>
              <li>Validity: 1 month</li>
            </ul>
            <p className="text-gray-700 mb-4">
              Schedule your sessions as per mutual availability. Full refund if
              suitable timing is not found.
            </p>
            <div className="text-center mt-4">
              <Link to="/contact">
                <button className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-bold hover:bg-blue-700 transition duration-300">
                  Join Now
                </button>
              </Link>
            </div>
          </div>

          <div className="text-center mt-8 animate__animated animate__fadeInUp">
            <p className="text-gray-700">
              Need assistance? Call or WhatsApp <strong>+91 8130548921</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineYogaClass;
