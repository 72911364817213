import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import "animate.css";

const Support = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div className="container mx-auto p-4 mt-28">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8 animate__animated animate__fadeInDown">
        Support
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-lg animate__animated animate__fadeInUp">
        <p className="mb-4 text-gray-700">
          Welcome to the Dietician Priyanka Support page. We are here to help
          you with any questions, issues, or concerns you may have regarding our
          services. Our goal is to provide you with the best support experience
          possible.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          1. How Can We Help?
        </h2>
        <p className="mb-4 text-gray-700">
          Whether you're looking for answers to your questions, need help with a
          service, or have any concerns, our support team is ready to assist
          you. Here are some of the ways we can help:
        </p>

        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>Troubleshooting technical issues</li>
          <li>Understanding our diet plans and services</li>
          <li>Account management and billing inquiries</li>
          <li>Guidance on how to use our website</li>
          <li>Feedback and suggestions</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          2. Contacting Support
        </h2>
        <p className="mb-4 text-gray-700">
          If you need to get in touch with our support team, you can contact us
          through the following channels:
        </p>

        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>
            Email:{" "}
            <a
              href="mailto:priyankadlm@gmail.com"
              className="text-blue-500 underline"
            >
              priyankadlm@gmail.com
            </a>
          </li>
          <li>Phone: +91-123-456-7890</li>
          <li>Live Chat: Available on our website during business hours</li>
          <li>
            Social Media: Reach out to us on Facebook, Instagram, or Twitter
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          3. Frequently Asked Questions (FAQs)
        </h2>
        <p className="mb-4 text-gray-700">
          Before contacting support, we recommend checking out our{" "}
          <a href="/pricing" className="text-blue-500 underline">
            Pricing and FAQs page
          </a>{" "}
          for answers to common questions. You might find the information you
          need there.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          4. Support Hours
        </h2>
        <p className="mb-4 text-gray-700">
          Our support team is available during the following hours:
        </p>

        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>Monday to Friday: 9:30 AM - 6:00 PM IST</li>
          <li>Saturday: 9:30 AM - 2:00 PM IST</li>
          <li>Sunday: Closed</li>
        </ul>

        <p className="mb-4 text-gray-700">
          We strive to respond to all inquiries within 24 hours. If you contact
          us outside of our support hours, we'll get back to you as soon as
          possible on the next business day.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          5. Feedback
        </h2>
        <p className="mb-4 text-gray-700">
          We value your feedback as it helps us improve our services. If you
          have any suggestions, compliments, or complaints, please don't
          hesitate to share them with us. Your input is important to us.
        </p>

        <p className="text-gray-700">
          Thank you for choosing Dietician Priyanka. We're here to support you
          on your health journey.
        </p>
      </div>
    </div>
  );
};

export default Support;
