import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [sortCriteria, setSortCriteria] = useState("submission");

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://dt-priyanka-1.onrender.com/api/appointments",
        // "http://localhost:5000/api/appointments",
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      setAppointments(response.data);
    } catch (err) {
      setError("Error fetching appointments");
    }
  };

  const sortAppointments = (appointments) => {
    if (sortCriteria === "submission") {
      return appointments.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sortCriteria === "appointment") {
      return appointments.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    return appointments;
  };

  return (
    <div className="mt-20 container mx-auto p-4 min-h-96">
      <h1 className="text-2xl font-bold mb-4">Appointment Submissions</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label htmlFor="sortCriteria" className="mr-2">
          Sort by:
        </label>
        <select
          id="sortCriteria"
          value={sortCriteria}
          onChange={(e) => setSortCriteria(e.target.value)}
          className="border rounded p-2"
        >
          <option value="submission">Submission Time</option>
          <option value="appointment">Appointment Date</option>
        </select>
      </div>
      <div className="space-y-4">
        {sortAppointments(appointments).map((appointment) => (
          <div
            key={appointment._id}
            className="p-4 bg-white rounded-lg shadow-md"
          >
            <h2 className="text-xl font-bold">{appointment.name}</h2>
            <p>{appointment.disease}</p>
            <p className="text-sm text-gray-600">Email: {appointment.email}</p>
            <p className="text-sm text-gray-600">Phone: {appointment.phone}</p>
            <p className="text-sm text-gray-600">
              Date: {new Date(appointment.date).toLocaleString()}
            </p>
            <p className="text-sm text-gray-600">
              Submitted on: {new Date(appointment.createdAt).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminAppointments;
