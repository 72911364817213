import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const HypertensionDiabeticDiet = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a Hypertension and Diabetic Diet?",
      answer:
        "A Hypertension and Diabetic Diet focuses on managing blood pressure and blood sugar levels through balanced nutrition. It includes tailored meal plans, lifestyle changes, and professional guidance to improve overall health.",
    },
    {
      question: "How can this diet help manage my conditions?",
      answer:
        "This diet helps manage hypertension and diabetes by providing specific dietary recommendations, exercise routines, and medical support. It aims to stabilize blood pressure and glucose levels, reducing the risk of complications.",
    },
    {
      question: "What foods should I include in my diet?",
      answer:
        "Include whole grains, lean proteins, fresh fruits, and vegetables. Limit intake of sodium, sugar, and unhealthy fats. Our program provides detailed meal plans to guide your choices.",
    },
    {
      question: "Can I still enjoy my favorite foods?",
      answer:
        "Yes, with moderation and careful planning. Our dietitians will help you incorporate your favorite foods into a balanced diet, ensuring you enjoy your meals while managing your health.",
    },
    {
      question: "How do I get started?",
      answer:
        "Book an appointment with our experts to get a personalized diet plan. Our team will support you through every step, ensuring you achieve your health goals.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Hypertension and Diabetic Diet - Manage Your Health</title>
        <meta
          name="description"
          content="Join our Hypertension and Diabetic Diet program to manage blood pressure and glucose levels through balanced nutrition and lifestyle changes."
        />
        <meta
          name="keywords"
          content="hypertension, diabetes, diet, health, nutrition, blood pressure, glucose levels"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">
              Hypertension and Diabetic Diet
            </h1>
            <p className="mt-2 text-md">
              Manage your health with balanced nutrition and lifestyle changes.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          <section className="text-center mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">1500</p>
                <p className="mt-2 text-gray-600">Patients Supported</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">25%</p>
                <p className="mt-2 text-gray-600">Blood Pressure Reduction</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">15%</p>
                <p className="mt-2 text-gray-600">Blood Sugar Reduction</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-3s">
                <p className="text-3xl font-bold">10%</p>
                <p className="mt-2 text-gray-600">Improved Lifestyle</p>
              </div>
            </div>
          </section>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <img
              src="/img/services.jpg"
              alt="Healthy Food"
              className="w-full h-auto object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-bold mb-4">Ideal for</h3>
            <p className="mb-4">
              Our program is ideal for individuals looking to manage
              hypertension and diabetes through balanced nutrition and lifestyle
              changes.
            </p>
            <h4 className="text-xl font-semibold mb-4">Health Management</h4>
            <p className="mb-4">
              We focus on stabilizing blood pressure and glucose levels,
              addressing the underlying health conditions alongside dietary
              adjustments.
            </p>
            <p className="mb-4">
              Our approach emphasizes sustainable lifestyle changes, ensuring
              long-term health benefits rather than quick fixes.
            </p>
            <p className="mb-4">
              Individual results vary based on factors like diet, medical
              history, activity level, and mental health. Our goal is
              comprehensive health improvement.
            </p>
            <p className="mb-4">
              Our diet plans promote permanent lifestyle changes and realistic
              goal-setting, fostering long-term health benefits.
            </p>
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </article>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <img
              src="/img/Exercise.png"
              alt="Exercise Routine"
              className="w-full h-full object-cover rounded-lg"
            />
            <h3 className="text-2xl font-bold mb-4">Exercise and Activity</h3>
            <p className="mb-4">
              Regular physical activity is essential for managing hypertension
              and diabetes. Our program includes personalized exercise routines
              suitable for all fitness levels.
            </p>
            <p className="mb-4">
              Whether you're a beginner or an experienced athlete, our trainers
              will guide you through effective workouts tailored to your needs.
            </p>
            <p className="mb-4">
              Exercise not only aids in managing health conditions but also
              improves overall cardiovascular health, strength, and endurance.
            </p>
            <h3 className="text-2xl font-bold mb-4">Dietary Counseling</h3>
            <p className="mb-4">
              Our expert dietitians provide personalized counseling to help you
              make informed food choices and achieve your health goals.
            </p>
            <p className="mb-4">
              We focus on a balanced diet that includes all essential nutrients
              while promoting long-term health benefits.
            </p>
            <p className="mb-4">
              Dietary counseling is integral to our program, ensuring that you
              develop healthy eating habits that last a lifetime.
            </p>
          </article>

          <article className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-3s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Comprehensive support from a dedicated team of experienced
                dietitians and Dietician Priyanka.
              </li>
              <li className="mb-2">
                Personalized diet plans focusing on holistic health improvement.
              </li>
              <li className="mb-2">
                Education on food choices and their long-term health benefits.
              </li>
              <li className="mb-2">
                Flexible programs that don’t feel restrictive.
              </li>
              <li className="mb-2">
                Emphasis on sustainable lifestyle changes rather than calorie
                counting alone.
              </li>
              <li className="mb-2">
                Convenient app-based tracking and support through 'Health
                Tracker'.
              </li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Support"
              className="w-full h-full object-cover rounded-lg"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </article>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default HypertensionDiabeticDiet;
