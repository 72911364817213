import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const GutCleaningProgram = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is a Gut Cleaning Program?",
      answer:
        "A Gut Cleaning Program focuses on detoxifying and improving the health of your digestive system. It involves dietary adjustments, hydration, and sometimes supplements to cleanse the gut and promote overall well-being.",
    },
    {
      question: "What are the benefits of a Gut Cleaning Program?",
      answer:
        "Benefits of a Gut Cleaning Program include improved digestion, better nutrient absorption, reduced bloating and gas, enhanced immune function, and overall improved gut health.",
    },
    {
      question: "What foods should I avoid during a Gut Cleaning Program?",
      answer:
        "During a Gut Cleaning Program, it's best to avoid processed foods, sugar, dairy, gluten, and foods high in unhealthy fats. Focus on whole, natural foods that are easy on the digestive system.",
    },
    {
      question: "How long does a Gut Cleaning Program take?",
      answer:
        "The duration of a Gut Cleaning Program can vary, but it typically ranges from a few days to a few weeks. It's important to follow the program guidelines and listen to your body.",
    },
    {
      question: "Can anyone do a Gut Cleaning Program?",
      answer:
        "Most people can benefit from a Gut Cleaning Program, but it's important to consult with a healthcare professional, especially if you have underlying health conditions or are on medication.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Gut Cleaning Program - Benefits, Foods to Avoid, and Duration
        </title>
        <meta
          name="description"
          content="Learn about the Gut Cleaning Program, including its benefits, foods to avoid, and duration to help cleanse your gut and improve digestive health."
        />
        <meta
          name="keywords"
          content="gut cleaning program, digestive health, detox, cleanse, nutrition"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Gut Cleaning Program</h1>
            <p className="mt-2 text-md">
              Discover the benefits and guidelines of a Gut Cleaning Program to
              improve your digestive health.
            </p>
          </div>
        </header>

        <main className="container mx-auto py-8 px-6 lg:px-16">
          {/* Benefits Section */}
          <section className="mb-12">
            <h2 className="text-3xl text-center font-semibold mb-6 animate__animated animate__fadeInUp">
              Benefits
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/4.jpg"
                alt="Benefits of Gut Cleaning Program"
                className="w-full h-96 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                A Gut Cleaning Program offers numerous benefits, including
                improved digestion, better nutrient absorption, and reduced
                bloating and gas. It can also enhance immune function and
                promote overall gut health.
              </p>
              <p className="mb-4">
                By following a structured program, you can reset your digestive
                system and enjoy a healthier gut.
              </p>
            </div>
          </section>

          {/* Foods to Avoid Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Foods to Avoid
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/3.jpg"
                alt="Foods to Avoid in Gut Cleaning Program"
                className="w-full h-64 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                During a Gut Cleaning Program, it's essential to avoid processed
                foods, sugar, dairy, gluten, and foods high in unhealthy fats.
                These can burden the digestive system and impede the cleansing
                process.
              </p>
              <p className="mb-4">
                Instead, focus on consuming whole, natural foods that support
                gut health.
              </p>
            </div>
          </section>

          {/* Duration Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Duration
            </h2>
            <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
              <img
                src="/img/programFeatures.jpg"
                alt="Duration of Gut Cleaning Program"
                className="w-full h-64 object-cover rounded-lg mb-6"
              />
              <p className="mb-4">
                The duration of a Gut Cleaning Program can vary, but it
                typically ranges from a few days to a few weeks. It's important
                to follow the program guidelines and listen to your body.
              </p>
              <p className="mb-4">
                Consistency and adherence to the program are key to achieving
                the best results.
              </p>
              <a
                href="/appointment"
                className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
              >
                Book an Appointment
              </a>
            </div>
          </section>

          {/* FAQ Section */}
          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default GutCleaningProgram;
