// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// const monthsData = [
//   {
//     name: "Basic Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹2100",
//     feature: [
//       "Get started on your health journey with our Basic Plan. It includes personalized dietician coaching, access to our app, meal planning assistance, and email support.",
//     ],
//   },
//   {
//     name: "Premium Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹3500",
//     feature: [
//       "Upgrade to our Premium Plan for enhanced features. In addition to the Basic Plan, you will receive video consultations, priority support, and personalized recipe recommendations.",
//     ],
//   },
//   {
//     name: "Ultimate Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹5000",
//     feature: [
//       "Experience the full benefits of personalized dietician coaching with our Ultimate Plan. Enjoy all the features of the Premium Plan, along with 24/7 chat support and exclusive workshops.",
//     ],
//   },
// ];

// const yearsData = [
//   {
//     name: "Basic Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹21000",
//     feature: [
//       "Get started on your health journey with our Basic Plan. It includes personalized dietician coaching, access to our app, meal planning assistance, and email support.",
//     ],
//   },
//   {
//     name: "Premium Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹35000",
//     feature: [
//       "Upgrade to our Premium Plan for enhanced features. In addition to the Basic Plan, you will receive video consultations, priority support, and personalized recipe recommendations.",
//     ],
//   },
//   {
//     name: "Ultimate Plan",
//     discount: "Up to 50% off on Yearly Plan",
//     price: "₹50000",
//     feature: [
//       "Experience the full benefits of personalized dietician coaching with our Ultimate Plan. Enjoy all the features of the Premium Plan, along with 24/7 chat support and exclusive workshops.",
//     ],
//   },
// ];

// const Pricing = () => {
//   const [selectedPlan, setSelectedPlan] = useState("month");

//   const handleMonth = () => {
//     setSelectedPlan("month");
//   };

//   const handleYear = () => {
//     setSelectedPlan("year");
//   };

//   const data = selectedPlan === "month" ? monthsData : yearsData;

//   return (
//     <section className="text-gray-600 body-font">
//       <div className="container px-5 py-4 mx-auto mt-10">
//         <div className="flex flex-col text-center w-full mb-10">
//           <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
//             Our Pricing
//           </h1>
//           <h2 className="text-sm font-medium title-font text-gray-900 md:mx-32">
//             We outline our flexible and affordable options to support you on
//             your journey to optimal health and nutrition. We believe that
//             everyone deserves access to personalized dietician guidance and
//             resources.
//           </h2>
//         </div>
//         <div>
//           <div className="flex justify-center rounded overflow-hidden mb-2">
//             <button
//               onClick={handleMonth}
//               className={`py-1 px-4 ${
//                 selectedPlan === "month"
//                   ? "bg-[#0A7CEA] text-white focus:outline-none border-2 border-[#0A7CEA]"
//                   : "border-2 border-[#0A7CEA]"
//               }`}
//             >
//               Monthly
//             </button>
//             <button
//               onClick={handleYear}
//               className={`py-1 px-4 ${
//                 selectedPlan === "year"
//                   ? "bg-[#0A7CEA] text-white focus:outline-none border-2 border-[#0A7CEA]"
//                   : "border-2 border-[#0A7CEA]"
//               }`}
//             >
//               Annually
//             </button>
//           </div>
//           <h1 className="text-md opacity-80 text-center text-gray-900 mb-6">
//             Save 50% on Yearly
//           </h1>
//           <div className="flex flex-wrap -m-4">
//             {data.map((item, index) => (
//               <div key={index} className="p-4 xl:w-1/3 md:w-1/2 w-full">
//                 <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
//                   <h2 className="text-md tracking-widest title-font mb-1 font-medium">
//                     {item.name}
//                   </h2>
//                   {item.discount && (
//                     <h2 className="text-sm mb-5">{item.discount}</h2>
//                   )}
//                   {item.feature.map((f, idx) => (
//                     <p
//                       key={idx}
//                       className="flex items-center text-gray-600 mb-5"
//                     >
//                       {f}
//                     </p>
//                   ))}
//                   <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4">
//                     <span>{item.price}</span>
//                     <span className="text-lg ml-1 font-normal text-gray-500">{`/${
//                       selectedPlan === "month" ? "mo" : "year"
//                     }`}</span>
//                   </h1>
//                   <Link to="/pricing">
//                     <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
//                       Choose Plan
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//         {/* Consultation Section */}
//         <div className="flex justify-center mt-10">
//           <div className="p-4  w-full">
//             <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
//               <h2 className="text-2xl tracking-widest title-font mb-1 font-bold flex align-middle justify-center">
//                 Consultation
//               </h2>
//               <h2 className="text-md tracking-widest title-font font-normal flex align-middle justify-center mb-5">
//                 One-time Consultation
//               </h2>
//               <p className="flex items-center justify-center text-gray-600 mb-5">
//                 Schedule a one-time consultation with our expert dietician to
//                 address your specific health and nutrition needs. This session
//                 is tailored to help you understand your dietary requirements,
//                 set achievable health goals, and create a personalized plan that
//                 suits your lifestyle. Whether you're looking to improve your
//                 eating habits, manage a health condition, or simply seek expert
//                 advice, our consultation provides a comprehensive approach to
//                 your well-being.
//               </p>
//               <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4">
//                 <span>₹500</span>
//               </h1>
//               <Link to="/appointment">
//                 <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
//                   Book Consultation
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Pricing;

import React from "react";
import { Link } from "react-router-dom";

const monthsData = [
  {
    name: "Basic Plan - 1 month",
    price: "₹2100",
    feature: [
      "Get started on your health journey with our Basic Plan. It includes personalized dietician coaching, access to our app, meal planning assistance, and email support.",
    ],
  },
  {
    name: "Premium Plan - 2 months",
    price: "₹3500",
    feature: [
      "Upgrade to our Premium Plan for enhanced features. In addition to the Basic Plan, you will receive video consultations, priority support, and personalized recipe recommendations.",
    ],
  },
  {
    name: "Ultimate Plan - 3 months",
    price: "₹5000",
    feature: [
      "Experience the full benefits of personalized dietician coaching with our Ultimate Plan. Enjoy all the features of the Premium Plan, along with 24/7 chat support and exclusive workshops.",
    ],
  },
  {
    name: "One-time Consultation",
    price: "₹500",
    feature: [
      "Schedule a one-time consultation with our expert dietician to address your specific health and nutrition needs. This session is tailored to help you understand your dietary requirements, set achievable health goals, and create a personalized plan that suits your lifestyle. Whether you're looking to improve your eating habits, manage a health condition, or simply seek expert advice, our consultation provides a comprehensive approach to your well-being.",
    ],
  },
  {
    name: "Therapeutic Diet Plan with Do's and Don'ts",
    price: "₹1500",
    feature: [
      "Our Therapeutic Diet Plan offers a comprehensive guide on dietary dos and don'ts tailored to your health needs. This plan is designed to support various health conditions and help you achieve your wellness goals with a structured approach.",
    ],
  },
  {
    name: "Weight Loss Packages",
    price: "",
    feature: [
      " Our Weight Loss Packages are customized based on your current weight and specific goals. Choose from a range of packages designed to help you achieve effective and sustainable weight loss with personalized support and guidance.",
    ],
  },
];

const Pricing = () => {
  const data = monthsData; // Always show monthly data

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-4 mx-auto mt-10">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Our Pricing
          </h1>
          <h2 className="text-sm font-medium title-font text-gray-900 md:mx-32">
            We outline our flexible and affordable options to support you on
            your journey to optimal health and nutrition. We believe that
            everyone deserves access to personalized dietician guidance and
            resources.
          </h2>
        </div>
        <div>
          {/* Removed the save 50% on yearly section */}
          <div className="flex flex-wrap -m-4">
            {data.map((item, index) => (
              <div key={index} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                <div className="h-full p-6 rounded-lg border-2 border-gray-200 flex flex-col relative overflow-hidden">
                  <h2 className="text-md tracking-widest title-font mb-1 font-medium">
                    {item.name}
                  </h2>
                  {item.feature.map((f, idx) => (
                    <p
                      key={idx}
                      className="flex items-center text-gray-600 mb-5"
                    >
                      {f}
                    </p>
                  ))}
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4">
                    <span>{item.price}</span>
                    {/* <span className="text-lg ml-1 font-normal text-gray-500">{`/mo`}</span> */}
                  </h1>
                  <Link to="/pricing">
                    <button className="text-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded">
                      Choose Plan
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
