import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import OurPrograms from "../components/OurPrograms";
import DietitianPriyanka from "../components/DietitianPriyanka";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Testimonals from "../components/Testimonals";
import SpecialOffers from "../components/Specialoffers";
import { Helmet } from "react-helmet";

function Home() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Dietician Priyanka | Personalized Dietician Healthcare</title>
      </Helmet>
      <Hero />
      <Features />
      <DietitianPriyanka />
      <OurPrograms />
      <SpecialOffers />
      <Testimonals />
      <Pricing />
    </>
  );
}

export default Home;
