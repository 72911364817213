import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { menu, close } from "ionicons/icons";
import axios from "axios";
import "tailwindcss/tailwind.css";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null); // Null to indicate loading
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAdmin(false);
        return;
      }

      try {
        // Verify the token with the backend
        const response = await axios.get(
          "https://dt-priyanka-1.onrender.com/api/admin/verifyToken",
          // "http://localhost:5000/api/admin/verifyToken",
          {
            headers: { "x-auth-token": token },
          }
        );

        if (response.data.success) {
          setIsAdmin(true); // Token is valid
        } else {
          setIsAdmin(false); // Token is invalid
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsAdmin(false); // Error during verification
      }
    };

    verifyToken();

    setIsMenuOpen(false);
    setIsDropdownOpen(false);
    setIsSecondDropdownOpen(false);
    window.scrollTo(0, 0); // Scroll to top whenever the location changes
  }, [location]);

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAdmin(false);
    navigate("/");
  };

  const handleMouseEnter = () => {
    if (!isMenuOpen) {
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMenuOpen) {
      setIsDropdownOpen(false);
    }
  };

  const handleSecondMouseEnter = () => {
    if (!isMenuOpen) {
      setIsSecondDropdownOpen(true);
    }
  };

  const handleSecondMouseLeave = () => {
    if (!isMenuOpen) {
      setIsSecondDropdownOpen(false);
    }
  };

  if (isAdmin === null) {
    return <div>Loading...</div>; // Or some spinner while checking the token
  }

  return (
    <header className="bg-white z-50 fixed top-0 left-0 w-full border-b-4 border-gray-300">
      {isAdmin ? null : (
        <div className="flex items-center bg-[#0F4B7C] py-1 justify-center">
          <Link to="appointment">
            <p className="flex items-center hover:no-underline text-white gap-2 text-xs p-2">
              <span className="font-semibold">Book an Appointment</span>
              <img src="arrow-r.png" alt="" className="max-w-[20px]" />
            </p>
          </Link>
        </div>
      )}
      <nav className="flex justify-between items-center w-[97%] mx-auto relative font-normal md:font-semibold">
        <div>
          <Link
            className="hover:text-gray-500"
            to={isAdmin ? "/admin-appointments" : "/"}
            onClick={() => setIsMenuOpen(false)}
          >
            <img
              className="block sm:hidden w-46 h-16 cursor-pointer"
              src="T-logo.png"
              alt="Logo"
            />
            <img
              className="hidden sm:block w-46 h-16 cursor-pointer"
              src="Logobg.png"
              alt="Logo"
            />
          </Link>
        </div>

        <div
          className={`nav-links duration-500 md:static fixed bg-white md:min-h-fit right-0 ${
            isMenuOpen
              ? "absolute top-20 rounded-xl p-4 z-30 border-b-4 border-gray-300"
              : "top-[-100%]"
          } md:w-auto w-[36%] flex items-center px-4 z-40`}
        >
          <ul className="flex md:flex-row flex-col md:items-center md:gap-[3vw] gap-4">
            {isAdmin ? (
              <>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-plan"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Plans
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-appointments"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Appointments
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-blogs"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-contacts"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Contacts
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-specialOffer"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Offer
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/admin-updateCredentials"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Update Credentials
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/about"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    About&nbsp;Us
                  </Link>
                </li>
                <li
                  className="relative cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <div className="hover:text-gray-500 flex">
                    Services
                    <svg
                      className="-mr-1 h-6 w-5"
                      viewBox="0 -2 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  {isDropdownOpen && (
                    <ul
                      className={`absolute ${
                        isMenuOpen ? "right-16 top-0 w-32" : "left-0 w-40"
                      } bg-white border rounded-md shadow-lg`}
                    >
                      <li>
                        <Link
                          className="block px-1 py-2 text-gray-800 hover:bg-gray-100"
                          to="/DeitPlans"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Diet Plans
                        </Link>
                      </li>
                      <li
                        className="relative cursor-pointer"
                        onMouseEnter={handleSecondMouseEnter}
                        onMouseLeave={handleSecondMouseLeave}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsSecondDropdownOpen(!isSecondDropdownOpen);
                        }}
                      >
                        <div className="hover:text-gray-500 flex ml-1">
                          Our&nbsp;Programs
                          <svg
                            className="-mr-1 h-6 w-5"
                            viewBox="0 -2 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        {isSecondDropdownOpen && (
                          <ul
                            className={`absolute ${
                              isMenuOpen
                                ? "right-28 top-0 w-40"
                                : "left-32 w-72"
                            } bg-white border rounded-md shadow-lg max-h-96 overflow-y-auto`}
                          >
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/HypertensionDiabeticDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Hypertension & Diabetic Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/ThyroidDiseaseDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Thyroid&nbsp;Disease Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/PCODandObesityDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                PCOD&nbsp;and&nbsp;Obesity Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/DietForLowImmunity"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Diet&nbsp;For Low Immunity
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/AnemiaDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Anemia&nbsp;Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/ArthritisAndBoneHealthDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Arthritis&nbsp;& Bone Health Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/PregnancyAndLactationDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Pregnancy&nbsp;And Lactation Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/ChildHealthDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Child&nbsp;Health Diet
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/VitaminTherapy"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Vitamin&nbsp;Therapy
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/UricAcidArthritis"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Uric&nbsp;Acid Arthritis
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/GutCleaningProgram"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Gut&nbsp;Cleaning Program
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="block px-1 md:px-4 py-2 text-gray-800 hover:bg-gray-100"
                                to="/HeartDiet"
                                onClick={() => setIsMenuOpen(false)}
                              >
                                Heart&nbsp;Diet
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link
                          className="block px-1 py-2 text-gray-800 hover:bg-gray-100"
                          to="/OnlineYogaProgram"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Online&nbsp;Yoga
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/bmicalculator"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    BMI
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/team"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Team
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/process"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Process
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/pricing"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    View&nbsp;Plans
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/blog"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-gray-500"
                    to="/Gallery"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Gallery
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        {isAdmin ? (
          <div className="flex items-center gap-4">
            <button
              className="bg-[#0F4B7C] text-white px-5 py-2 rounded-full hover:bg-[#0A7CEA]"
              onClick={handleLogout}
            >
              Logout
            </button>
            <IonIcon
              icon={isMenuOpen ? close : menu}
              onClick={onToggleMenu}
              className="text-3xl cursor-pointer md:hidden"
            />
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
              <button className="bg-[#0F4B7C] text-white px-5 py-2 rounded-full hover:bg-[#0A7CEA]">
                Contact&nbsp;Us
              </button>
            </Link>
            <IonIcon
              icon={isMenuOpen ? close : menu}
              onClick={onToggleMenu}
              className="text-3xl cursor-pointer md:hidden"
            />
          </div>
        )}
      </nav>
    </header>
  );
};

export default NavBar;
