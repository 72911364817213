import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner"; // Ensure this import is correct

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(""); // New state for subject
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  // const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    // setIsSubmitted(true); // Disable form submission

    try {
      const response = await axios.post(
        "https://dt-priyanka-1.onrender.com/api/contact",
        {
          name,
          email,
          subject, // Include subject in the request
          message,
        }
      );

      if (response.data.success) {
        toast.success("Message sent successfully!");
        setName("");
        setEmail("");
        setSubject(""); // Clear subject
        setMessage("");
      } else {
        toast.error("Error sending message");
      }
    } catch (err) {
      toast.error("Error sending message");
    } finally {
      setIsLoading(false); // End loading
      // setIsSubmitted(false); // Enable form submission
    }
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-4 mx-auto">
        <div className="flex sm:flex-nowrap max-[768px]:flex-col max-[768px]:gap-4 flex-wrap bg-blue-10 px-4">
          <div className="lg:w-[48%] md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 sm:mr-10">
            <h3 className="text-3xl mb-4 font-semibold">Contact Us:</h3>
            <form onSubmit={handleSubmit}>
              <div className="relative mb-4">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full bg-[#f0f0f0] rounded border border-gray-300 focus:border-[#0A7CEA] focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                  disabled={isLoading} // Disable input during loading
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-[#f0f0f0] rounded border border-gray-300 focus:border-[#0A7CEA] focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                  disabled={isLoading} // Disable input during loading
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="subject"
                  className="leading-7 text-sm text-gray-600"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full bg-[#f0f0f0] rounded border border-gray-300 focus:border-[#0A7CEA] focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                  disabled={isLoading} // Disable input during loading
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full bg-[#f0f0f0] rounded border border-gray-300 focus:border-[#0A7CEA] focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  required
                  disabled={isLoading} // Disable input during loading
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-[#0F4B7C] text-white px-5 py-2 rounded-full hover:bg-[#0A7CEA]"
                disabled={isLoading} // Disable button during loading
              >
                {isLoading ? (
                  <ThreeDots
                    height="30"
                    width="30"
                    color="#ffffff"
                    ariaLabel="three-dots-loading"
                    visible={true}
                  />
                ) : (
                  "Send Message"
                )}
              </button>
            </form>
          </div>
          <div className="lg:w-[49%] md:w-1/2 max-[768px]:w-full max-[768px]:h-[500px] rounded-lg overflow-hidden px-10 py-2 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=new%20delhi,india+(Nutrition)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              title="Map Location"
            ></iframe>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* ToastContainer should be used here */}
    </section>
  );
};

export default ContactForm;
