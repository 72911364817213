import React, { useState, useEffect } from "react";
import axios from "axios";

// const blogs = [
//   {
//     category: "Weight Loss Tips",
//     title: "10 Effective Strategies for Sustainable Weight Loss",
//     content:
//       "Discover proven strategies for long-term weight loss success. Learn how to create healthy habits, set achievable goals, and make sustainable lifestyle changes. Discover proven strategies for long-term weight loss success. Learn how to create healthy habits, set achievable goals, and make sustainable lifestyle changes. Discover proven strategies for long-term weight loss success. Learn how to create healthy habits, set achievable goals, and make sustainable lifestyle changes. Discover proven strategies for long-term weight loss success. Learn how to create healthy habits, set achievable goals, and make sustainable lifestyle changes.",
//     img: "B-Section.png",
//   },
//   {
//     category: "Weight Loss Tips",
//     title: "The Role of Portion Control in Weight Management",
//     content:
//       "Learn how portion control can help you manage your weight effectively. Find practical tips for controlling portion sizes and avoiding overeating.",
//     img: "B-Section-1.png",
//   },
//   {
//     category: "Weight Loss Tips",
//     title: "Understanding Emotional Eating and How to Overcome It",
//     content:
//       "Explore the connection between emotions and eating habits. Get valuable insights on how to identify emotional triggers and develop healthier coping mechanisms.",
//     img: "B-Section-2.png",
//   },
//   {
//     category: "Weight Loss Tips",
//     title: "How to Stay Motivated on Your Weight Loss Journey",
//     content:
//       "Find effective strategies to stay motivated and overcome obstacles during your weight loss journey. Get tips on setting realistic goals, tracking progress, and celebrating achievements.",
//     img: "B-Section-3.png",
//   },
//   {
//     category: "Healthy Eating",
//     title: "The Benefits of a Plant-Based Diet for Overall Health",
//     content:
//       "Explore the advantages of adopting a plant-based diet. Learn about the potential health benefits, nutrient-rich plant-based foods, and tips for transitioning to a plant-based lifestyle.",
//     img: "B-Section-4.png",
//   },
//   {
//     category: "Healthy Eating",
//     title: "Understanding Macronutrients: Carbohydrates, Proteins, and Fats",
//     content:
//       "Get a comprehensive overview of macronutrients and their role in a balanced diet. Discover the best sources of each macronutrient and how to incorporate them into your meals.",
//     img: "B-Section-5.png",
//   },
//   {
//     category: "Fitness and Exercise",
//     title: "Cardio vs. Strength Training: Which Is Better for Weight Loss?",
//     content:
//       "Explore the benefits of both cardio and strength training exercises for weight loss. Find out how to combine them effectively to maximize your results.",
//     img: "B-Section-6.png",
//   },
//   {
//     category: "Fitness and Exercise",
//     title: "Building a Home Workout Routine: Tips and Best Practices",
//     content:
//       "Discover how to create an effective workout routine at home. Learn about equipment options, exercise techniques, and ways to stay motivated.",
//     img: "B-Section-7.png",
//   },
//   {
//     category: "Mindset and Motivation",
//     title: "Developing a Positive Body Image and Self-Confidence",
//     content:
//       "Explore techniques for cultivating a positive body image and improving self-confidence. Learn how to embrace your body and appreciate your unique qualities.",
//     img: "B-Section-8.png",
//   },
//   {
//     category: "Mindset and Motivation",
//     title: "Overcoming Self-Sabotage in Your Weight Loss Journey",
//     content:
//       "Identify self-sabotaging behaviors and learn strategies to overcome them. Discover how to shift your mindset and develop healthier habits.",
//     img: "B-Section-9.png",
//   },
//   {
//     category: "Recipes and Meal Planning",
//     title: "Healthy and Flavorful Lunch Ideas for a Busy Lifestyle",
//     content:
//       "Discover a variety of tasty and nutritious lunch options that are perfect for those with busy schedules. These recipes are quick to make and packed with essential nutrients.",
//     img: "B-Section-10.png",
//   },
//   {
//     category: "Recipes and Meal Planning",
//     title: "Satisfying and Nutritious Dinner Recipes for Weight Loss",
//     content:
//       "Find a collection of flavorful dinner recipes that are both satisfying and supportive of your weight loss goals. These recipes are designed to be healthy and delicious.",
//     img: "B-Section-11.png",
//   },
// ];

const Section = () => {
  const [expandedBlog, setExpandedBlog] = useState(null);

  const [blogs, setBlogs] = useState([]);

  const handleBlogClick = (index) => {
    setExpandedBlog(index === expandedBlog ? null : index);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://dt-priyanka-1.onrender.com/api/blogs"
      );
      setBlogs(response.data);
    } catch (err) {
      console.log("Error fetching blogs");
    }
  };

  return (
    <div className="mt-20 container mx-auto px-5 ">
      {/* Your existing header section */}
      <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
        {/* Background image and logo */}
        <div className="absolute inset-0 top-0 flex items-start justify-center">
          <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
        </div>
        <div className="z-[1]">
          <img src="T-logo.png" alt="" />
        </div>
        {/* Introduction */}
        <div className="flex flex-col gap-4 items-center z-[1]">
          <div>
            <h1 className="text-2xl font-semibold">Our Blogs</h1>
          </div>
          <div>
            <p>
              Welcome to the Blog section of Dietician Priyanka, your trusted
              source for insightful articles, tips, and expert advice on
              dietician and wellness. Here, we strive to provide you with
              engaging and informative content that will inspire and empower you
              to make informed decisions about your health. Explore our blog to
              discover a wealth of resources that cover a wide range of topics
              related to nutrition, fitness, and overall well-being.
            </p>
          </div>
        </div>
      </div>
      {/* Blog section */}
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-12 px-12 divide-x-[0.25px] divide-y-[0.25px] divide-blue-90">
            {blogs.map((blog, index) => (
              <div
                key={index}
                className="px-12 py-4 md:w-1/2 flex flex-col items-start bg-[#FAFAFA] cursor-pointer"
                onClick={() => handleBlogClick(index)}
              >
                <span className="text-md font-medium border-b-2 border-[#0A7CEA] mb-12">
                  {blog.category}
                </span>
                <div className="flex items-center mb-8">
                  {/* <div className="w-[20%]"></div> */}
                  {/* <div className="w-[80%]"> */}

                  <div className="w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                    {/* <img className="relative right-0" src={blog.img} alt="" /> */}
                    <img
                      src={require(`../../uploadImages/${blog.image}`)}
                      alt={blog.title}
                      // className="relative right-0 w-[38rem] h-[16rem]"
                      className="object-cover object-center w-full max-h-56"
                    />
                  </div>
                </div>
                <div className="flex justify-between max-[640px] flex-col gap-4 items-start">
                  <div className=" text-left mr-4 max-[500px] w-full">
                    <h2 className="sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4">
                      {blog.title}
                    </h2>
                    <p className="leading-relaxed">
                      {expandedBlog === index
                        ? blog.content // Show full content if expanded
                        : blog.content.slice(0, 100) + "..."}{" "}
                      {/* Show truncated content if not expanded */}
                    </p>
                  </div>
                  <div className="max-[500px] w-full">
                    {/* Read more button */}
                    <button className="inline-flex items-center bg-[#0A7CEA] hover:bg-[#0D4372] text-white border-0 py-1 px-3 focus:outline-none rounded font-semibold">
                      {expandedBlog === index ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section;
