import React, { useState } from "react";
import { GiBodyHeight } from "react-icons/gi";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { BiBody } from "react-icons/bi";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

function BmiCalculator() {
  const [bmi, setBmi] = useState();
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [value, setValue] = useState("1");
  const [showModal, setShowModal] = useState(false);

  const calculate = () => {
    const weightNum = parseFloat(weight);
    const heightNum = parseFloat(height) / 100; // Convert height to meters
    if (isNaN(weightNum) || isNaN(heightNum) || heightNum === 0) {
      setBmi("Invalid input");
    } else {
      let bmiValue = (weightNum / (heightNum * heightNum)).toFixed(2);
      setBmi(bmiValue);
    }
    setShowModal(true);
  };

  const getBmiCategory = (bmi, gender) => {
    if (gender === "1") {
      // Male
      if (bmi < 18.5) return "Underweight";
      if (bmi >= 18.5 && bmi < 24.9) return "Normal weight";
      if (bmi >= 25 && bmi < 29.9) return "Overweight";
      return "Obesity";
    } else {
      // Female
      if (bmi < 18.5) return "Underweight";
      if (bmi >= 18.5 && bmi < 24.9) return "Normal weight";
      if (bmi >= 25 && bmi < 29.9) return "Overweight";
      return "Obesity";
    }
  };

  const resetForm = () => {
    setName("");
    setWeight("");
    setHeight("");
    setValue("1");
    setBmi(null);
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>BMI Calculator | Dietician Priyanka</title>
      </Helmet>
      <div className="mt-20 container mx-auto px-5">
        <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
          <div className="absolute inset-0 top-0 flex items-start justify-center">
            <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
          </div>
          <div className="z-[1]">
            <img src="T-logo.png" alt="" />
          </div>
          <div className="flex flex-col gap-4 items-center z-[1]">
            <div>
              <h1 className="text-2xl font-semibold">
                Calculate Body Mass Index
              </h1>
            </div>
            <div className="text-left">
              <p className="text-left">
                The body mass index (BMI) is a measure that uses your height and
                weight to work out if your weight is healthy.
              </p>
              <p>
                The BMI calculation divides an adult's weight in kilograms by
                their height in metres squared. For example, A BMI of 25 means
                25kg/m2.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-center items-center my-6 ">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="p-8 w-full max-w-lg bg-white rounded-lg shadow-lg flex flex-col space-y-5 border-2 border-[#0A7CEA]"
        >
          <h1 className="text-3xl font-bold text-center text-gray-800 ">
            BMI Calculator
          </h1>
          <p className="text-center text-gray-600">
            Calculate your Body Mass Index (BMI) to understand your health
            better.
          </p>
          <div className="relative mb-5">
            <MdDriveFileRenameOutline
              className="absolute top-2 left-3 text-gray-400"
              size={30}
            />
            <input
              type="text"
              placeholder="Your name..."
              className="w-full py-2 pl-12 pr-4 border rounded-lg focus:outline-none focus:border-blue-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="relative mb-5">
            <BiBody className="absolute top-2 left-3 text-gray-400" size={30} />
            <input
              type="text"
              placeholder="Weight, e.g., 60kg"
              className="w-full py-2 pl-12 pr-4 border rounded-lg focus:outline-none focus:border-blue-500"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
          <div className="relative mb-5">
            <GiBodyHeight
              className="absolute top-2 left-3 text-gray-400"
              size={30}
            />
            <input
              type="text"
              placeholder="Height, e.g., 160cm"
              className="w-full py-2 pl-12 pr-4 border rounded-lg focus:outline-none focus:border-blue-500"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <div className="flex space-x-5">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="1"
                  className="form-radio text-blue-500"
                  checked={value === "1"}
                  onChange={(e) => setValue(e.target.value)}
                />
                <span>Male</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  value="2"
                  className="form-radio text-pink-500"
                  checked={value === "2"}
                  onChange={(e) => setValue(e.target.value)}
                />
                <span>Female</span>
              </label>
            </div>
          </div>
          <button
            onClick={calculate}
            className="py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600 transition duration-300"
          >
            Calculate
          </button>
        </motion.div>

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h2 className="text-2xl font-bold mb-4">Your BMI Result</h2>
              <p className="text-lg">Name: {name}</p>
              <p className="text-lg">Your BMI: {bmi}</p>
              <p className="text-lg">Category: {getBmiCategory(bmi, value)}</p>
              <div className="mt-5">
                <h2 className="text-lg font-semibold text-center">
                  BMI Categories:
                </h2>
                <ul className="list-disc list-inside text-gray-700">
                  <li>Underweight: BMI less than 18.5</li>
                  <li>Normal weight: BMI 18.5–24.9</li>
                  <li>Overweight: BMI 25–29.9</li>
                  <li>Obesity: BMI 30 or greater</li>
                </ul>
              </div>
              <button
                onClick={resetForm}
                className="mt-4 py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:bg-red-600 transition duration-300"
              >
                Close
              </button>
            </motion.div>
          </div>
        )}
      </div>
    </>
  );
}

export default BmiCalculator;
