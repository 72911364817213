// AdminSpecialOffers.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminSpecialOffers = () => {
  const [specialOffers, setSpecialOffers] = useState([]);
  const [newSpecialOffer, setNewSpecialOffer] = useState({
    type: "",
    text: "",
  });

  useEffect(() => {
    // Fetch products when the component mounts
    fetchSpecialOffers();
  }, []);

  // Fetch special offers from the backend
  const fetchSpecialOffers = async () => {
    try {
      const response = await axios.get(
        "https://dt-priyanka-1.onrender.com/api/special-offers"
      );
      setSpecialOffers(response.data);
    } catch (error) {
      console.error("Error fetching special offers:", error);
    }
  };

  const addSpecialOffer = async () => {
    try {
      await axios.post(
        "https://dt-priyanka-1.onrender.com/api/special-offers",
        newSpecialOffer
      );
      // Fetch updated special offers after adding a new one
      const response = await axios.get(
        "https://dt-priyanka-1.onrender.com/api/special-offers"
      );
      setSpecialOffers(response.data);
      // Reset the form
      setNewSpecialOffer({ type: "", text: "" });
      toast.success("Offer added successfully");
    } catch (error) {
      console.error("Error adding special offer:", error);
    }
  };

  const deleteSpecialOffer = async (id) => {
    try {
      await axios.delete(
        `https://dt-priyanka-1.onrender.com/api/special-offers/${id}`
      );
      // Fetch updated special offers after deletion
      fetchSpecialOffers();
    } catch (error) {
      console.error("Error deleting special offer:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const handleTypeChange = (selectedType) => {
    setNewSpecialOffer({ ...newSpecialOffer, type: selectedType });
  };

  return (
    <div className="w-full bg-blue-700/60 p-4 ">
      <div className="mt-[6rem]  mx-auto  max-w-xl p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Admin Special Offers Page</h2>

        {/* Form to add a new special offer */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Type:
          </label>
          <select
            value={newSpecialOffer.type}
            onChange={(e) => handleTypeChange(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          >
            <option value="" disabled>
              Select Type
            </option>
            <option value="promotion">Promotion</option>
            <option value="specialOffer">Special Offer</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Text:
          </label>
          <input
            type="text"
            value={newSpecialOffer.text}
            onChange={(e) =>
              setNewSpecialOffer({ ...newSpecialOffer, text: e.target.value })
            }
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
          />
        </div>

        <button
          onClick={addSpecialOffer}
          className=" text-white px-4 py-2 rounded-md bg-[#193760]  focus:outline-none focus:ring focus:border-blue-300"
        >
          Add Special Offer
        </button>

        {/* Display existing special offers */}
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Existing Special Offers:</h3>
          <ul className="list-disc pl-6">
            {specialOffers.map((offer) => (
              <li key={offer._id} className="mb-2">
                <span className="font-semibold">{offer.type}:</span>{" "}
                {offer.text}
                <button
                  onClick={() => deleteSpecialOffer(offer._id)}
                  className="ml-2 text-red-600 hover:text-red-800 focus:outline-none"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSpecialOffers;
