import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "animate.css";

const VitaminTherapy = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What are the benefits of Vitamin A?",
      answer:
        "Vitamin A is essential for maintaining healthy vision, skin, and immune function. It also supports cell growth and differentiation.",
    },
    {
      question: "How does Vitamin C help with skin health?",
      answer:
        "Vitamin C is crucial for collagen production, which helps maintain skin elasticity and repair. It also acts as an antioxidant, protecting the skin from damage caused by free radicals.",
    },
    {
      question: "What role does Vitamin D play in the body?",
      answer:
        "Vitamin D helps regulate calcium and phosphorus in the body, which is essential for maintaining healthy bones and teeth. It also supports immune system function.",
    },
    {
      question: "How can Vitamin E benefit your skin?",
      answer:
        "Vitamin E acts as an antioxidant that helps protect skin cells from damage. It also has moisturizing properties and can help reduce the appearance of scars and wrinkles.",
    },
    {
      question:
        "What is the difference between water-soluble and fat-soluble vitamins?",
      answer:
        "Water-soluble vitamins, like Vitamin C and B-complex vitamins, dissolve in water and are not stored in the body, so they need to be consumed regularly. Fat-soluble vitamins, like Vitamins A, D, E, and K, dissolve in fats and can be stored in the body for later use.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Vitamin Therapy - Understanding Vitamin Differences</title>
        <meta
          name="description"
          content="Explore the benefits of vitamin therapy and understand the differences between water-soluble and fat-soluble vitamins. Get expert advice on vitamin supplements."
        />
        <meta
          name="keywords"
          content="vitamin therapy, vitamin benefits, water-soluble vitamins, fat-soluble vitamins, vitamin supplements"
        />
      </Helmet>
      <div className="mt-28 bg-gray-100 min-h-screen">
        <header className="bg-[#0F4B7C] text-white py-4 animate__animated animate__fadeInDown">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-semibold">Vitamin Therapy</h1>
            <p className="mt-2 text-md">
              Understanding the benefits of vitamins and their differences.
            </p>
          </div>
        </header>

        <section className="container mx-auto py-12 px-6 lg:px-16">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-semibold mb-6 animate__animated animate__fadeInUp">
              Our Achievements
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 justify-center">
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn">
                <p className="text-3xl font-bold">2000+</p>
                <p className="mt-2 text-gray-600">Satisfied Clients</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-1s">
                <p className="text-3xl font-bold">90%</p>
                <p className="mt-2 text-gray-600">Customer Satisfaction Rate</p>
              </div>
              <div className="bg-white shadow-md p-6 rounded-lg animate__animated animate__zoomIn animate__delay-2s">
                <p className="text-3xl font-bold">120+</p>
                <p className="mt-2 text-gray-600">Vitamin Therapy Programs</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp">
            <img
              src="/img/skin.jpg"
              alt="Healthy Skin and Hair"
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-bold mb-4">Ideal for</h3>
            <p className="mb-4">
              Our vitamin therapy program is designed to provide tailored advice
              and supplements to support overall health and wellness. We focus
              on optimizing vitamin intake to address individual health needs.
            </p>
            <h4 className="text-xl font-semibold mb-4">Vitamin Benefits</h4>
            <p className="mb-4">
              Understanding the benefits of different vitamins is crucial for
              maintaining good health. Our program includes expert guidance on
              the role of essential vitamins and how they contribute to overall
              well-being.
            </p>

            <h4 className="text-xl font-semibold mb-4">Vitamin Supplements</h4>
            <p className="mb-4">
              We offer personalized recommendations for vitamin supplements
              based on your specific health needs. Whether you need to boost
              your energy, support your immune system, or improve skin health,
              we provide solutions tailored to you.
            </p>
            <img
              src="/img/food.jpg"
              alt="Hair Treatments"
              className="w-full h-96 object-cover rounded-lg mb-6"
            />
            <h4 className="text-xl font-semibold mb-4">
              Personalized Recommendations
            </h4>
            <p className="mb-4">
              Our experts provide personalized vitamin recommendations and plans
              based on your health goals and lifestyle. We help you understand
              which vitamins are best for you and how to incorporate them into
              your daily routine.
            </p>
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-1s">
            <h3 className="text-2xl font-bold mb-4">Program Features</h3>
            <ul className="list-disc pl-5 mb-4">
              <li className="mb-2">
                Tailored vitamin therapy plans based on individual health needs.
              </li>
              <li className="mb-2">
                Expert guidance on the benefits of different vitamins and
                supplements.
              </li>
              <li className="mb-2">
                Personalized recommendations for optimal vitamin intake.
              </li>
              <li className="mb-2">
                Access to high-quality vitamin supplements and educational
                resources.
              </li>
              <li className="mb-2">
                Ongoing support and follow-up to track progress and adjust
                plans.
              </li>
            </ul>
            <img
              src="/img/programFeatures.jpg"
              alt="Supportive Community"
              className="w-full h-96 object-cover rounded-lg"
            />
            <a
              href="/appointment"
              className="inline-block mt-8 px-6 py-2 bg-[#0F4B7C] text-white rounded-md hover:bg-[#0A7CEA] transition duration-300"
            >
              Book an Appointment
            </a>
          </div>

          <div className="bg-white shadow-md rounded-lg p-8 mb-12 animate__animated animate__fadeInUp animate__delay-2s">
            <h3 className="text-2xl font-bold mb-4">
              Frequently Asked Questions
            </h3>
            <div className="space-y-4">
              {faqItems.map((item, index) => (
                <div key={index}>
                  <button
                    className="w-full text-left bg-gray-200 py-3 px-4 rounded-lg focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    <h4 className="text-lg font-medium">{item.question}</h4>
                  </button>
                  {activeIndex === index && (
                    <div className="mt-2 pl-4 pr-2">
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VitaminTherapy;
