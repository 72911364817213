import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Section from "../components/blogs/Section";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Blogs | Dietician Priyanka</title>
      </Helmet>
      <div className="mt-20">
        <Section />
      </div>
    </>
  );
};

export default Blogs;
