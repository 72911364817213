// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const AdminPlan = () => {
//   const [plans, setPlans] = useState([]);
//   const [error, setError] = useState("");
//   const [filterPlan, setFilterPlan] = useState("all");
//   const [filterType, setFilterType] = useState("all");

//   useEffect(() => {
//     const fetchPlans = async () => {
//       try {
//         const response = await axios.get(
//           "https://dt-priyanka-1.onrender.com/api/plans"
//         );
//         const sortedPlans = response.data.sort(
//           (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//         );
//         setPlans(sortedPlans);
//       } catch (error) {
//         console.error("Error fetching plans", error);
//         setError("Error fetching plans");
//       }
//     };

//     fetchPlans();
//   }, []);

//   const filteredPlans = plans.filter((plan) => {
//     const matchesPlan = filterPlan === "all" || plan.plan === filterPlan;
//     const matchesType = filterType === "all" || plan.planType === filterType;
//     return matchesPlan && matchesType;
//   });

//   return (
//     <div className="mt-20 container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">
//         Admin Panel - Plan Submissions
//       </h1>
//       {error && <div className="text-red-500 mb-4">{error}</div>}
//       <div className="flex justify-between mb-4">
//         <div>
//           <label htmlFor="filterPlan" className="mr-2">
//             Filter by Plan:
//           </label>
//           <select
//             id="filterPlan"
//             value={filterPlan}
//             onChange={(e) => setFilterPlan(e.target.value)}
//             className="border rounded p-2"
//           >
//             <option value="all">All</option>
//             <option value="Basic Plan">Basic Plan</option>
//             <option value="Premium Plan">Premium Plan</option>
//             <option value="Ultimate Plan">Ultimate Plan</option>
//           </select>
//         </div>
//         <div>
//           <label htmlFor="filterType" className="mr-2">
//             Filter by Type:
//           </label>
//           <select
//             id="filterType"
//             value={filterType}
//             onChange={(e) => setFilterType(e.target.value)}
//             className="border rounded p-2"
//           >
//             <option value="all">All</option>
//             <option value="month">Monthly</option>
//             <option value="year">Yearly</option>
//           </select>
//         </div>
//       </div>
//       <div className="overflow-x-auto">
//         <table className="min-w-full bg-white border border-gray-200 shadow-lg rounded-lg">
//           <thead className="bg-gray-200">
//             <tr>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Name
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Email
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Phone
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Plan
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Plan Type
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Price
//               </th>
//               <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Date/Time
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredPlans.map((plan) => (
//               <tr key={plan._id} className="even:bg-gray-100">
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.name}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.email}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.phone}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.plan}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.planType}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {plan.price}
//                 </td>
//                 <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
//                   {new Date(plan.createdAt).toLocaleString()}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default AdminPlan;

import React, { useEffect, useState } from "react";
import axios from "axios";

const AdminPlan = () => {
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState("");
  const [filterPlan, setFilterPlan] = useState("all");
  const [filterDuration, setFilterDuration] = useState("all");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          "https://dt-priyanka-1.onrender.com/api/plans"
        );
        const sortedPlans = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPlans(sortedPlans);
      } catch (error) {
        console.error("Error fetching plans", error);
        setError("Error fetching plans");
      }
    };

    fetchPlans();
  }, []);

  const filteredPlans = plans.filter((plan) => {
    const matchesPlan = filterPlan === "all" || plan.plan === filterPlan;
    const matchesDuration =
      filterDuration === "all" || plan.duration === filterDuration;
    return matchesPlan && matchesDuration;
  });

  return (
    <div className="mt-20 container mx-auto p-4 min-h-96">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Admin Panel - Plan Submissions
      </h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="flex justify-between mb-4">
        <div>
          <label htmlFor="filterPlan" className="mr-2">
            Filter by Plan:
          </label>
          <select
            id="filterPlan"
            value={filterPlan}
            onChange={(e) => setFilterPlan(e.target.value)}
            className="border rounded p-2"
          >
            <option value="all">All</option>
            <option value="Basic Plan">Basic Plan</option>
            <option value="Premium Plan">Premium Plan</option>
            <option value="Ultimate Plan">Ultimate Plan</option>
          </select>
        </div>
        <div>
          <label htmlFor="filterDuration" className="mr-2">
            Filter by Duration:
          </label>
          <select
            id="filterDuration"
            value={filterDuration}
            onChange={(e) => setFilterDuration(e.target.value)}
            className="border rounded p-2"
          >
            <option value="all">All</option>
            <option value="1 Month">1 Month</option>
            <option value="2 Months">2 Months</option>
            <option value="3 Months">3 Months</option>
          </select>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-lg rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Phone
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Plan
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Duration
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date/Time
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPlans.map((plan) => (
              <tr key={plan._id} className="even:bg-gray-100">
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.name}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.email}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.phone}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.plan}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.duration}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {plan.price}
                </td>
                <td className="py-4 px-6 border-b border-gray-200 text-sm text-gray-700">
                  {new Date(plan.createdAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPlan;
