import React from "react";
import "animate.css/animate.min.css";
import "./style.css";

const AHero = () => {
  return (
    <div className="mt-20 container px-5 mx-auto animate__animated animate__fadeIn">
      <div className="flex flex-col gap-8 items-center py-10 px-8 sm:px-20 relative">
        <div className="absolute inset-0 top-0 flex items-start justify-center">
          <img className="h-auto max-w-full" src="Abstrac-bg.png" alt="" />
        </div>
        <div className=" z-[1]">
          <img src="T-logo.png" alt="" />
        </div>
        <div className="flex flex-col gap-4 items-center  z-[1]">
          <div>
            <h1 className="text-3xl font-semibold">
              About Us - Welcome to Dietician Priyanka Healthcare
            </h1>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AHero;
