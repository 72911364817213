import React from "react";
import "animate.css";

const TermsOfService = () => {
  return (
    <div className="container mx-auto p-4 mt-28">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8 animate__animated animate__fadeInDown">
        Terms of Service
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-lg animate__animated animate__fadeInUp">
        <p className="mb-4 text-gray-700">
          Welcome to the Dietician Priyanka website. These Terms of Service
          govern your use of our website and services. By accessing or using our
          website, you agree to comply with and be bound by these terms. Please
          read them carefully.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          1. Acceptance of Terms
        </h2>
        <p className="mb-4 text-gray-700">
          By accessing our website, you agree to be bound by these Terms of
          Service, all applicable laws, and regulations. If you do not agree
          with any part of these terms, you are prohibited from using our
          website.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          2. Use of Services
        </h2>
        <p className="mb-4 text-gray-700">
          The content and services provided on our website are for your
          personal, non-commercial use only. You agree not to copy, reproduce,
          modify, distribute, or create derivative works based on the content or
          services provided without explicit permission from Dietician Priyanka.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          3. User Responsibilities
        </h2>
        <p className="mb-4 text-gray-700">
          You are responsible for maintaining the confidentiality of your
          account information and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account or any other security breaches.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          4. Intellectual Property
        </h2>
        <p className="mb-4 text-gray-700">
          All content, including but not limited to text, graphics, logos,
          images, and software on this website, is the property of Dietician
          Priyanka or its content suppliers and is protected by intellectual
          property laws. Unauthorized use of any content may violate copyright,
          trademark, and other laws.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          5. Limitation of Liability
        </h2>
        <p className="mb-4 text-gray-700">
          Dietician Priyanka shall not be liable for any damages arising out of
          or in connection with the use or inability to use the services or
          content provided on this website. This includes, but is not limited
          to, direct, indirect, incidental, or consequential damages.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          6. Third-Party Links
        </h2>
        <p className="mb-4 text-gray-700">
          Our website may contain links to third-party websites that are not
          owned or controlled by Dietician Priyanka. We are not responsible for
          the content, privacy policies, or practices of any third-party
          websites. You access these links at your own risk.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          7. Modifications to Terms of Service
        </h2>
        <p className="mb-4 text-gray-700">
          We reserve the right to modify these Terms of Service at any time. Any
          changes will be effective immediately upon posting on this page. Your
          continued use of our website after any modifications constitutes your
          acceptance of the revised terms.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          8. Governing Law
        </h2>
        <p className="mb-4 text-gray-700">
          These Terms of Service are governed by and construed in accordance
          with the laws of India. Any disputes arising out of or related to
          these terms will be subject to the exclusive jurisdiction of the
          courts located in [Your City], India.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          9. Contact Information
        </h2>
        <p className="text-gray-700">
          If you have any questions or concerns about these Terms of Service,
          please contact us at{" "}
          <a
            href="mailto:priyankadlm@gmail.com"
            className="text-blue-500 underline"
          >
            priyankadlm@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
