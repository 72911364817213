import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Main from "../components/contact/Main";
import Form from "../components/contact/Form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Contact = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Contact | Dietician Priyanka</title>
      </Helmet>
      <Main />
      <Form />
      <div className="container px-5 py-4 mt-4 mx-auto max-w-screen-xl">
        <div className="bg-[#fafafa] flex flex-col md:flex-row items-center justify-between py-10 px-8 sm:px-20 relative">
          <div className="md:w-[80%] z-[1]">
            <h1 className="text-xl font-semibold mb-2 text-gray-900 text-center md:text-left">
              Are you ready to embark on a transformative journey towards better
              health and wellness?
            </h1>
            <p className="w-full text-center md:text-left">
              {" "}
              Join us at Dietician Priyanka and let us guide you on the path to
              a healthier and happier you.
            </p>
          </div>
          <div className="mt-4 md:mt-0 z-[1]">
            <Link to="/pricing">
              {" "}
              <button className="inline-flex items-center bg-[#0D4372]  text-white border-0 py-2 px-3 focus:outline-none hover:bg-blue-500 rounded text-base">
                Join us Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
