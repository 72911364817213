import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Null to indicate loading

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        // Verify the token with the backend
        const response = await axios.get(
          "https://dt-priyanka-1.onrender.com/api/admin/verifyToken",
          // "http://localhost:5000/api/admin/verifyToken",
          {
            headers: { "x-auth-token": token },
          }
        );

        if (response.data.success) {
          setIsAuthenticated(true); // Token is valid
        } else {
          setIsAuthenticated(false); // Token is invalid
        }
      } catch (error) {
        setIsAuthenticated(false); // Error during verification
      }
    };

    verifyToken();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or some spinner while checking the token
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
